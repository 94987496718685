/* eslint-disable indent */
import type { RefObject } from 'react';
import type {
  ChartOptions,
  LegendOptions,
  PlotOptions,
  SeriesOptionsType,
  TooltipOptions,
  XAxisOptions,
  YAxisOptions,
} from 'highcharts';
import type { ScatterData } from '../components/Charts/ScatterWithSummary';
import {
  PATCH_VISUALS_DATA,
  RESET_ALL_REPORT_DATA,
  SET_VISUAL_CONTROLS_REF,
  TOGGLE_SECONDARY_AXIS,
  TOGGLE_VISUAL_FULLSCREEN,
  UPDATE_DATA_FILES,
  UPDATE_DROPDOWN_SELECTIONS,
  UPDATE_FILES_DROPDOWN_SELECTIONS,
  UPDATE_FILE_DROPDOWN_SELECTION,
  UPDATE_FULL_SCREEN_REF,
  UPDATE_REPORT_CONFIG,
  UPDATE_SECONDARY_AXIS_SELECTION,
  UPDATE_SHARED_DROPDOWNS,
  UPDATE_SINGLE_SELECTION,
  UPDATE_TAB_INDEX,
  UPDATE_TREE_CHART,
  UPDATE_VISUALS_DATA,
} from '../constants/reducers';
import type { SummaryCard } from '../components/Optimise/SummaryCards';
export interface SwitcherContent {
  name: string;
  visualContent: number[];
}

export enum VisualType {
  CELL_GRID = 'cell-grid',
  DENDRO_GRID = 'dendro-grid',
  OPTIMISER_GRID = 'optimiser-grid',
  OPTIMISER_PLAN_GRID = 'plan-optimiser-grid',
  CDT_GRID = 'cdt-grid',
  COLUMN = 'column',
  STACKED_COLUMN = 'stackedColumn',
  TREE = 'tree',
  KPI_TREE = 'kpi-tree',
  VENN = 'venn',
  SCATTER = 'scatter',
  DENDROGRAM = 'dendrogram',
  CDT_DENDROGRAM = 'cdt-dendrogram',
  CUSTOM = 'custom',
  CARDS = 'cards',
  PRODUCT_SUMMARY = 'product-summary',
  SUMMARY_CARDS = 'summary-cards',
  SUMMARY_GRID = 'summaryGrid',
}

export enum EXPORT_DATA_STATUS {
  PROCESSING = 'Processing',
  COMPLETED = 'Completed',
  FAILED = 'Failed',
}

export enum VisualContainerType {
  TABS = 'tabs',
  LIST = 'list',
}

export interface Visual {
  id: number;
  type?: VisualType;
  apiUrl?: string;
  hasMeasureDropdown?: boolean;
  sharedDropdown?: boolean;
  colSpan?: number;
  container?: VisualContainerType;
  visuals?: Visual[];
  isSecondaryAxisVisible?: boolean;
  navigationTab?: {
    label: string;
    icon?: string;
  };
}

export interface ReportConfig extends ReportMetaData {
  configuration: Configuration;
  parameters: {
    report_name: string;
    template_id: string;
    template_requests: ReportParameters[];
  };
  schedule_history?: Pick<
    ReportConfig,
    'run_id' | 'report_name' | 'end_time' | 'status'
  >[];
  export_data?: {
    [key: string | number]: {
      url: string;
      status: EXPORT_DATA_STATUS;
    };
  };
}

interface Configuration {
  switchers: Record<string, SwitcherContent[]>;
  visuals: Record<string, Visual[]>;
}

interface VisualError {
  message: string;
  reason: string;
}

export interface VisualData<T = object> {
  dropdownConfig?: DropdownConfig[];
  visualDropdownConfig?: DropdownConfig[];
  sharedDropdown?: DropdownConfig[];
  headers?: { [key: string]: Header[] };
  rows?: { [key: string]: T[] };
  isVisible?: number[];
  stickyColumns?: number[];
  measureDropdownId?: number;
  measures?: { [key: string]: Measure }; // To maintain old reports
  measure?: Measure; // To maintain old reports
  measureFormats?: { [key: string]: Measure };
  seriesData?:
    | { [key: string]: SeriesOptionsType[] }
    | { [key: string]: TreeChartData[] }
    | ScatterData[];
  vennData?: { [key: string]: number[] };
  vennLegendItems?: string[];
  vennSections?: number[];
  xAxis?: XAxisOptions;
  yAxis?: YAxisOptions;
  tooltip?: TooltipOptions;
  plotOptions?: PlotOptions;
  chart?: ChartOptions;
  legend?: LegendOptions;
  categoryDropdownId?: number;
  categoryConfig?: { [key: string]: string[] };
  error?: VisualError;
  isSearchable?: number[];
  cards?: { [key: string]: Array<Card | SummaryCard> };
  treeLayout?: 'top' | 'left' | 'bottom' | 'right';
  tooltipType?: 'column' | 'stackedColumn';
  cdtRootName?: string;
  columnWidths?: { [key: string]: number };
  summary?: ProductSummary;
  files?: Record<string, FileData>;
  fileDropdownSelections?: string;
  visualHeader?: {
    title: string;
    icon?: string;
  };
  isClickable?: {
    key: string;
  };
  info?: Record<string, string | number>;
  hasChildGrid?: boolean;
}

export interface FileData extends Omit<VisualData, 'files'> {
  visualDropdownConfig: DropdownConfig[];
}

export interface ReportState {
  reportConfig: ReportConfig;
  tabIndex: number;
  visualsData: { [key: string]: VisualData };
  fullscreenVisual: number | null;
  dropdownSelections: DropdownSelections;
  sharedDropdowns: SharedDropdowns;
  fullScreenRef: RefObject<HTMLDivElement>;
  treeCharts: null | Record<number, TreeChart>;
  visualRefs: RefObject<{ [key: string]: HTMLDivElement }>;
  visualControlsRef: {
    [key: string | number]: RefObject<HTMLDivElement>;
  };
  secondaryDropdownSelections?: {
    [key: string]: {
      selection?: DropdownOptions;
      isActive: boolean;
    };
  };
}

interface UpdateReportConfig {
  type: typeof UPDATE_REPORT_CONFIG;
  reportConfig: ReportConfig;
}

interface UpdateTabIndex {
  type: typeof UPDATE_TAB_INDEX;
  tabIndex: number;
}

interface UpdateVisualsData {
  type: typeof UPDATE_VISUALS_DATA;
  id: number | string;
  data: VisualData;
}

interface PatchVisualsData {
  type: typeof PATCH_VISUALS_DATA;
  id: number | string;
  data: Partial<VisualData>;
}

interface UpdateDataFile {
  type: typeof UPDATE_DATA_FILES;
  id: number | string;
  dropdownKey: string;
  data: VisualData;
}

interface UpdateFileDropdownSelection {
  type: typeof UPDATE_FILE_DROPDOWN_SELECTION;
  id: number | string;
  newKey: string;
}

interface UpdateFilesDropdownSelections {
  type: typeof UPDATE_FILES_DROPDOWN_SELECTIONS;
  dropdownIndex: number;
  selectedItemKey: number;
  visualIds: number[];
}

interface ToggleVisualFullscreen {
  type: typeof TOGGLE_VISUAL_FULLSCREEN;
  visualId: number;
}

export interface DropdownSelections {
  [key: string]: { [key: string]: string };
}

export interface SharedDropdowns {
  [key: string]: DropdownConfig[];
}

interface UpdateSingleSelection {
  type: typeof UPDATE_SINGLE_SELECTION;
  visualIndex: string | number;
  visualId: number | string;
  dropdownKey: string;
}

interface UpdateDropdownSelections {
  type: typeof UPDATE_DROPDOWN_SELECTIONS;
  visualIndex: string | number;
  dropdownId: number;
  selectedItemKey: number | string;
}

interface UpdateSharedDropdowns {
  type: typeof UPDATE_SHARED_DROPDOWNS;
  tabId: number | string;
  dropdownConfigs: DropdownConfig[];
}

interface UpdateFullScreenRef {
  type: typeof UPDATE_FULL_SCREEN_REF;
  ref: RefObject<HTMLDivElement>;
}

interface UpdateTreeCharts {
  type: typeof UPDATE_TREE_CHART;
  id: number;
  chart: TreeChart;
}

interface ToggleSecondaryAxis {
  type: typeof TOGGLE_SECONDARY_AXIS;
  id: number | string;
  isActive: boolean;
}

interface UpdateSecondaryAxisSelection {
  type: typeof UPDATE_SECONDARY_AXIS_SELECTION;
  id: number | string;
  selection: DropdownOptions;
}

interface SetVisualContainerRef {
  type: typeof SET_VISUAL_CONTROLS_REF;
  id: string | number;
  ref: RefObject<HTMLDivElement>;
}

interface ResetAllReportData {
  type: typeof RESET_ALL_REPORT_DATA;
}

export type ReportAction =
  | UpdateReportConfig
  | UpdateTabIndex
  | UpdateVisualsData
  | ToggleVisualFullscreen
  | UpdateDropdownSelections
  | UpdateFullScreenRef
  | UpdateSingleSelection
  | UpdateSharedDropdowns
  | UpdateTreeCharts
  | UpdateDataFile
  | UpdateFileDropdownSelection
  | ToggleSecondaryAxis
  | UpdateSecondaryAxisSelection
  | UpdateFilesDropdownSelections
  | ResetAllReportData
  | SetVisualContainerRef
  | PatchVisualsData;

export const DEFAULT_REPORT_CONFIG = {
  run_id: '',
  id: '',
  report_name: '',
  report_type: null,
  start_time: '',
  end_time: '',
  user_name: '',
  user_id: '',
  status: '',
  url_route: '',
  section: '',
  schedule_data: null,
  user_group: null,
  parameters: {
    report_name: '',
    template_id: '',
    template_requests: [],
  },
  configuration: {
    switchers: {},
    visuals: {},
  },
};

export const initialReportState: ReportState = {
  tabIndex: 0,
  reportConfig: DEFAULT_REPORT_CONFIG,
  visualsData: {},
  fullscreenVisual: null,
  dropdownSelections: {},
  sharedDropdowns: {},
  fullScreenRef: null,
  treeCharts: null,
  visualRefs: null,
  visualControlsRef: {},
};

export const reportReducer = (
  state: ReportState,
  action: ReportAction
): ReportState => {
  switch (action.type) {
    case UPDATE_REPORT_CONFIG: {
      return {
        ...state,
        reportConfig: action.reportConfig,
        dropdownSelections:
          state.reportConfig.id === action.reportConfig.id
            ? state.dropdownSelections
            : {},
        visualsData:
          state.reportConfig.id === action.reportConfig.id
            ? state.visualsData
            : {},
      };
    }
    case UPDATE_TAB_INDEX:
      return {
        ...state,
        tabIndex: action.tabIndex,
      };

    case UPDATE_VISUALS_DATA: {
      const { measures, measureFormats, ...visualData } = action.data;
      const dropdownIds = action.data.dropdownConfig?.map((dd) => dd.id);
      const sharedDDIds = action.data.sharedDropdown?.map((dd) => dd.id);

      const combinedDropdownConfigs = action.data.sharedDropdown
        ? [...action.data.sharedDropdown, ...action.data.dropdownConfig]
        : action.data.dropdownConfig;

      const shouldMerge = !sharedDDIds?.every((id) => dropdownIds.includes(id));
      return {
        ...state,
        visualsData: {
          ...state.visualsData,
          [action.id]: {
            ...visualData,
            measureFormats: measures || measureFormats,
            dropdownConfig: shouldMerge
              ? combinedDropdownConfigs
              : action.data.dropdownConfig,
          },
        },
      };
    }

    case PATCH_VISUALS_DATA: {
      return {
        ...state,
        visualsData: {
          ...state.visualsData,
          [action.id]: {
            ...state.visualsData[action.id],
            ...action.data,
          },
        },
      };
    }

    case UPDATE_DATA_FILES: {
      const {
        dropdownConfig = [],
        sharedDropdown = [],
        ...fileData
      } = action.data;
      const visualDropdownConfig = [...dropdownConfig, ...sharedDropdown];
      return {
        ...state,
        visualsData: {
          ...state.visualsData,
          [action.id]: {
            ...state.visualsData[action.id],
            files: {
              ...state.visualsData[action.id]?.files,
              [action.dropdownKey]: {
                ...fileData,
                sharedDropdown,
                visualDropdownConfig: visualDropdownConfig,
              },
            },
          },
        },
      };
    }

    case UPDATE_FILE_DROPDOWN_SELECTION: {
      return {
        ...state,
        visualsData: {
          ...state.visualsData,
          [action.id]: {
            ...state.visualsData[action.id],
            fileDropdownSelections: action.newKey,
          },
        },
      };
    }

    case UPDATE_FILES_DROPDOWN_SELECTIONS: {
      const { dropdownIndex, selectedItemKey } = action;
      return {
        ...state,
        visualsData: {
          ...Object.entries(state.visualsData).reduce(
            (acc, [id, visualData]) => {
              if (action.visualIds.includes(Number(id))) {
                const newKey = visualData.fileDropdownSelections.split('_');
                newKey[dropdownIndex] = selectedItemKey.toString();
                acc[id] = {
                  ...visualData,
                  fileDropdownSelections: newKey.join('_'),
                };
              } else {
                acc[id] = visualData;
              }
              return acc;
            },
            {}
          ),
        },
      };
    }

    case TOGGLE_VISUAL_FULLSCREEN:
      return {
        ...state,
        fullscreenVisual: action.visualId,
      };

    case UPDATE_SINGLE_SELECTION:
      return {
        ...state,
        dropdownSelections: {
          ...state.dropdownSelections,
          [action.visualIndex]: {
            ...state.dropdownSelections[action.visualIndex],
            [action.visualId]: action.dropdownKey,
          },
        },
      };

    case UPDATE_DROPDOWN_SELECTIONS: {
      const { dropdownId, selectedItemKey } = action;

      const loadedIds = Object.keys(
        state.dropdownSelections[action.visualIndex]
      );

      return {
        ...state,
        dropdownSelections: {
          ...state.dropdownSelections,
          [action.visualIndex]: {
            ...state.dropdownSelections[action.visualIndex],
            ...loadedIds.reduce((acc, id) => {
              const newKey =
                state.dropdownSelections[action.visualIndex][id].split('_');
              const dropdownIndex = state.visualsData[
                id
              ].dropdownConfig.findIndex(({ id }) => id === dropdownId);
              newKey[dropdownIndex] = selectedItemKey.toString();
              return { ...acc, [id]: newKey.join('_') };
            }, {}),
          },
        },
      };
    }

    case UPDATE_SHARED_DROPDOWNS: {
      const { tabId, dropdownConfigs } = action;
      return {
        ...state,
        sharedDropdowns: {
          ...state.sharedDropdowns,
          [tabId]: dropdownConfigs,
        },
      };
    }

    case UPDATE_FULL_SCREEN_REF: {
      return {
        ...state,
        fullScreenRef: action.ref,
      };
    }

    case SET_VISUAL_CONTROLS_REF: {
      return {
        ...state,
        visualControlsRef: {
          ...state.visualControlsRef,
          [action.id]: action.ref,
        },
      };
    }

    case UPDATE_TREE_CHART: {
      return {
        ...state,
        treeCharts: {
          ...state.treeCharts,
          [action.id]: action.chart,
        },
      };
    }

    case TOGGLE_SECONDARY_AXIS:
      return {
        ...state,
        secondaryDropdownSelections: {
          ...state.secondaryDropdownSelections,
          [action.id]: {
            ...state.secondaryDropdownSelections?.[action.id],
            isActive: action.isActive,
          },
        },
      };

    case UPDATE_SECONDARY_AXIS_SELECTION:
      return {
        ...state,
        secondaryDropdownSelections: {
          ...state.secondaryDropdownSelections,
          [action.id]: {
            ...state.secondaryDropdownSelections?.[action.id],
            selection: action.selection,
          },
        },
      };

    case RESET_ALL_REPORT_DATA: {
      return initialReportState;
    }
  }
};
