import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import { MAX_RETRY_ATTEMPTS } from '../constants/api';
import { inflate } from 'pako';
import jwtDecode from 'jwt-decode';
import { apiRequestFailure } from '../constants/posthog';
import posthog from 'posthog-js';
import { getBaseUrl } from './configuration';
import type { PreSignedURLResponse } from '../types/apiTypes';

export enum ERROR_TYPE {
  VALIDATION = 'validation',
  NOT_FOUND = 'not_found',
}

export const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const tryRequest = async <T,>(
  options: AxiosRequestConfig,
  attempt: number,
  token: string
): Promise<T> => {
  try {
    const response: AxiosResponse<T | PreSignedURLResponse> =
      await axios.request(options);

    if (
      response.status === 201 &&
      (response.data as PreSignedURLResponse).presigned_url
    ) {
      const { decompress } = response.data as PreSignedURLResponse;
      const { data } = await axios.request({
        url: (response.data as PreSignedURLResponse).presigned_url,
        ...(decompress
          ? { responseType: 'arraybuffer', decompress: true }
          : {}),
      });

      if (decompress) {
        const inflated = inflate(data, { to: 'string' });
        const json = JSON.parse(inflated);
        return {
          data: json,
        } as T;
      } else {
        return { data } as T;
      }
    } else {
      return response as T;
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      throw error;
    }

    switch (error.response?.data?.type) {
      case ERROR_TYPE.VALIDATION:
        throw error;
      case ERROR_TYPE.NOT_FOUND:
        throw new Error(
          `Backend Request Error: ${error.response?.data?.message}`
        );
      default: {
        if (attempt > MAX_RETRY_ATTEMPTS) {
          throw new Error(`Backend Request Error: ${error.message}`);
        }
      }
    }
    const { sub }: Auth0AccessToken = jwtDecode(token);
    posthog.capture(apiRequestFailure, {
      user: sub,
      user_group: 0,
      payload: {
        url: options.url,
        attempt,
      },
    });

    await wait(2 ** attempt * 100);
    return tryRequest(options, attempt + 1, token);
  }
};

export const apiRequest = async <T,>(
  path: string,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  token: string,
  data?,
  signal?: AbortSignal
): Promise<T> => {
  const url = `${getBaseUrl()}${path}`;
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };

  return tryRequest<T>({ url, headers, method, data, signal }, 1, token);
};

export default apiRequest;
