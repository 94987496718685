import type { FunctionComponent } from 'react';
import { useContext, useState } from 'react';
import { ShareKnowledge } from '@carbon/icons-react';
import SidePanelSection from '../SidePanel/SidePanelSection';
import AllUsers from './AllUsers';
import type { SharedUser } from './ShareReport';
import SidePanelActions from '../SidePanel/SidePanelActions';
import { ModalContext } from '../../providers/ModalProvider';
import { useAuth0 } from '@auth0/auth0-react';
import apiRequest from '../../api';
import { AppContext } from '../../providers/AppProvider';
import { CACHE_KEY } from '../../constants/api';
import { SidePanelContext } from '../../providers/SidePanelProvider';
import Gravatar from '../Gravatar';
import SharedUsers from './SharedUsers';
import { getSharedUserArray } from '../../utils';
import {
  transferReportFailure,
  transferReportSuccess,
} from '../../constants/posthog';
import usePosthog from '../../utils/posthog';

interface TransferReportProps {
  bannerId: number;
  groupId: number;
  reports: ReportMetaData[];
}

const TransferReport: FunctionComponent<TransferReportProps> = ({
  bannerId,
  groupId,
  reports,
}) => {
  const itemPlural = reports.length === 1 ? '' : 's';
  const these = reports.length === 1 ? 'this' : 'these';
  const [transferUser, setTransferUser] = useState<SharedUser>(null);
  const [transferring, setTransferring] = useState<boolean>(false);
  const [sharedWith, setSharedWith] = useState<SharedUser[]>([]);
  const { getAccessTokenSilently } = useAuth0();
  const { toggleModal, updateModal } = useContext(ModalContext);
  const { clearCacheForKey } = useContext(AppContext);
  const { toggleSidePanelExpand } = useContext(SidePanelContext);
  const posthogEvent = usePosthog();

  const updateSharedWithData = (sharedUserObject: {
    [key: string]: string;
  }) => {
    const sharedUserArray = getSharedUserArray(sharedUserObject);
    setSharedWith(sharedUserArray);
  };

  const confirmTransfer = () => {
    updateModal({
      type: 'warning',
      title: 'Confirm ownership transfer',
      body: (
        <div>
          Are you sure you want to transfer the ownership of {these} (
          {reports.length}) report{itemPlural}?
          <div className="flex align-center justify-center">
            <Gravatar name={transferUser.name} /> will be the new owner.
          </div>
        </div>
      ),
      primaryCTAText: 'Transfer',
      onPrimaryCTAClick: handleTransfer,
      secondaryCTAText: 'Cancel',
    });
  };

  const handleTransfer = async () => {
    setTransferring(true);
    const token = await getAccessTokenSilently();

    try {
      toggleModal(false);
      await apiRequest('/reports/transfer', 'POST', token, {
        user_id: transferUser.userId,
        run_ids: reports.map((r) => r.run_id),
      });
      clearCacheForKey(CACHE_KEY.REPORTS);
      toggleSidePanelExpand(false);
      updateModal({
        type: 'success',
        title: 'Report ownership transferred',
        body: (
          <div>
            <div>Ownsership has been successfully transferred to</div>
            <div className="flex align-center justify-center">
              <Gravatar name={transferUser.name} />
            </div>
          </div>
        ),
      });
      posthogEvent(transferReportSuccess, {
        userIds: transferUser.userId,
      });
    } catch {
      posthogEvent(transferReportFailure);
      updateModal({
        type: 'error',
        title: 'Transfer of ownership failed',
        body: 'An error occurred when attempting to transfer ownership. Please try again.',
      });
    } finally {
      toggleSidePanelExpand(false);
      setTransferring(false);
    }
  };

  return (
    <>
      <SidePanelSection sectionHeader={`Report${itemPlural} to be transferred`}>
        {reports.map((report) => {
          return (
            <div key={report.run_id} className="description">
              {report.report_name}
            </div>
          );
        })}
      </SidePanelSection>
      <SidePanelSection
        sectionHeader="Shared with"
        sectionDescription="Select users to unshare this report"
      >
        <SharedUsers
          bannerId={bannerId}
          groupId={groupId}
          run_id={reports[0].run_id}
          sharedWith={sharedWith}
          removedUsers={[transferUser]}
          selectionButtons={{ primary: 'Clear', secondary: 'Select' }}
          crossOrCheck="check"
          handleSharedUserClick={(user) =>
            transferUser === user
              ? setTransferUser(null)
              : setTransferUser(user)
          }
          updateSharedWithData={updateSharedWithData}
        />
      </SidePanelSection>
      <SidePanelSection
        sectionHeader="Team members"
        sectionDescription={`Select the new owner of the report${itemPlural}`}
        showDivider={false}
      >
        <AllUsers
          apiUrl={`/user-groups/${groupId}/users`}
          checkedUsers={[transferUser]}
          filterUsers={() => true}
          selectionButtons={{ primary: 'Clear', secondary: 'Select' }}
          handleUserClick={(user: SharedUser) =>
            setTransferUser(user === transferUser ? null : user)
          }
        />
      </SidePanelSection>
      <SidePanelActions
        actions={[
          {
            name: `Transfer report${itemPlural}`,
            disabled: transferring || !transferUser,
            loading: transferring,
            icon: ShareKnowledge,
            onClick: confirmTransfer,
          },
        ]}
      />
    </>
  );
};

export default TransferReport;
