import { useContext } from 'react';
import { DateTime } from 'luxon';
import posthog from 'posthog-js';
import { AppContext } from '../providers/AppProvider';
interface PosthogPayload {
  [key: string]:
    | string
    | number
    | string[]
    | boolean
    | {
        [key: string]: string | number;
      }
    | AnalyticEngineParams;
}
const usePosthog = () => {
  const { groupId, user } = useContext(AppContext);
  const posthogEvent = (eventName: string, payload?: PosthogPayload) => {
    posthog.capture(eventName, {
      user,
      date: DateTime.now().toLocaleString(),
      month: DateTime.now().monthLong,
      user_group: groupId,
      payload: payload || '',
    });
  };

  return posthogEvent;
};

export default usePosthog;
