export enum CACHE_KEY {
  MODULES = 'modules',
  CUSTOM_GROUPS = 'custom-groups',
  NOTIFICATIONS = 'notifications',
  HIERARCHY_URLS = 'hierarchyUrls',
  HIERARCHIES = 'hierarchies',
  DIMENSIONS = 'dimensions',
  REPORTS = 'reports',
  REPORT = 'report',
  KPI_TREE = 'kpi-tree',
  DATES = 'dates',
  CUSTOM_PRODUCTS = 'products-custom',
  CUSTOM_LOCATION = 'stores-custom',
  HOMEPAGE = 'homepage',
  USER_LIST = 'user-list',
  ADMIN_PANEL_CONFIG = 'admin-panel-config',
  GENAI_QUESTIONS = 'genai-questions',
  GENAI_FEEDBACK_ITEMS = 'genai-feedback-items',
}

export const MAX_RETRY_ATTEMPTS = 5;
