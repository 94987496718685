/* eslint-disable indent */
import type { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { useContext, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  InlineLoading,
  InlineNotification,
  TextInput,
  Tooltip,
} from '@carbon/react';
import { useAuth0 } from '@auth0/auth0-react';
import { AppContext } from '../../providers/AppProvider';
import ParameterSummary from './ParameterSummary';
import { getReportData } from '../../utils/reportUtils';
import apiRequest from '../../api';
import { ModalContext } from '../../providers/ModalProvider';
import {
  submitReportSuccess,
  submitReportFailure,
  submitEditReportSuccess,
  submitEditReportFailure,
  submitModalCreateAnotherClick,
  submitModalViewWorkspaceClick,
  reportBuilderPinGroupSuccess,
  reportBuilderPinGroupFailure,
} from '../../constants/posthog';
import { WEBSOCKET_BROADCAST_CHANNEL } from '../../providers/WebsocketProvider';
import usePosthog from '../../utils/posthog';
import {
  isAdvancedSettingValid,
  isRuleSetValid,
} from '../../utils/RuleValidation';
import { Checkmark } from '../../assets/icons/checkmark';
import { Crossmark } from '../../assets/icons/crossmark';
import type { HierarchyOverlap } from './ReportBuilder';

interface SummaryProps {
  advancedSettings: AdvancedSettings[] | Setting[];
  setReportName: Dispatch<SetStateAction<string>>;
  overlap: HierarchyOverlap;
  reportName: string;
  resetReport: () => void;
  editParameter: (
    parameterKey: string,
    parameter: ReportParameter,
    index: number
  ) => void;
  removeParameter: (parameterKey: string, parameter: ReportParameter) => void;
  showSubmit?: boolean;
  analyticEngineParams: AnalyticEngineParams;
  deleteFavourite: (
    type: 'group' | 'item',
    name: string,
    parentName?: string
  ) => Promise<void>;
  refetchFavourites: () => Promise<void>;
  editReportId: string;
  isEditAction: boolean;
  minRequirements: RuleSet;
}

interface SubmitRequirement {
  key: string;
  valid: boolean;
  message: string;
}

const ReportSummary: FunctionComponent<SummaryProps> = ({
  reportName,
  overlap,
  setReportName,
  resetReport,
  editParameter,
  removeParameter,
  showSubmit,
  analyticEngineParams,
  deleteFavourite,
  refetchFavourites,
  editReportId,
  minRequirements,
  isEditAction,
  advancedSettings,
}) => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const { section, moduleId } = useParams();
  const {
    groupId,
    reportModules,
    reportParameters,
    user,
    bannerId,
    favouriteGroups,
  } = useContext(AppContext);
  const posthogEvent = usePosthog();
  const [submitting, setSubmitting] = useState(false);
  const { updateModal, toggleModal } = useContext(ModalContext);
  const nameInputRef = useRef<HTMLInputElement | null>(null);
  const broadcastChannel = new BroadcastChannel(WEBSOCKET_BROADCAST_CHANNEL);

  const hasRestrictions = advancedSettings?.some(
    (setting) => setting?.restriction
  );

  const requirements: SubmitRequirement[] = [
    {
      key: 'report-name',
      valid: reportName.trim().length > 0,
      message: 'Report has a name',
    },
    {
      key: 'min-reqs-met',
      valid: isRuleSetValid(minRequirements, reportParameters),
      message: 'Report meets minimum requirements',
    },
    hasRestrictions && {
      key: 'advanced-setting-validation',
      valid: isAdvancedSettingValid(advancedSettings, analyticEngineParams),
      message: 'Advanced settings are valid',
    },
  ].filter(Boolean);

  const isReportValid = requirements.every(({ valid }) => valid);

  const submitReport = async () => {
    setSubmitting(true);
    const trimmedName = reportName.trim();
    const { template_id } = reportModules
      .find((module) => module.section === section)
      .reports.find((r) => r.url_route === moduleId);

    const reportData = getReportData(trimmedName, reportParameters);
    const overlappingDimensions = Object.keys(overlap).filter((key) => {
      return overlap[key].hasOverlap;
    });
    const { report_name, ...parameters } = reportData;
    const payload = {
      template_id,
      run_id: isEditAction ? editReportId : null,
      report_name,
      template_requests: [
        {
          ...parameters,
          ...(analyticEngineParams
            ? { analytic_engine_params: analyticEngineParams }
            : {}),
          template_id,
          user_group: groupId,
          overlap: overlappingDimensions,
        },
      ],
    };
    // eslint-disable-next-line no-console
    console.log(payload);
    try {
      const token = await getAccessTokenSilently();
      await apiRequest('/convert', 'POST', token, payload);
      isEditAction
        ? redirectToWorkspace()
        : updateModal({
            type: 'success',
            title: 'Report submitted',
            body: 'Your report has been successfully submitted. View it in your workspace, or create another.',
            primaryCTAText: 'Create another',
            onPrimaryCTAClick: () => {
              posthogEvent(submitModalCreateAnotherClick);
              toggleModal(false);
            },
            onSecondaryCTAClick: () => {
              posthogEvent(submitModalViewWorkspaceClick);
              redirectToWorkspace();
            },
            secondaryCTAText: 'View workspace',
          });
      const submitSuccess = editReportId
        ? submitEditReportSuccess
        : submitReportSuccess;
      posthogEvent(submitSuccess, {
        reportName,
        analyticEngineParams,
        reportType: moduleId,
      });

      broadcastChannel.postMessage(JSON.stringify({ action: 'REFRESH' }));
    } catch (error) {
      updateModal({
        type: 'error',
        title: 'Something went wrong',
        body: 'There was an issue submitting your report. Please make sure your parameters are correct and try again.',
      });
      const submitFailure = editReportId
        ? submitEditReportFailure
        : submitReportFailure;
      posthogEvent(submitFailure);
    } finally {
      setSubmitting(false);
    }
  };

  const redirectToWorkspace = () => {
    toggleModal(false);
    navigate('/workspace/my-reports');
  };

  const pinGroup = async (
    hierarchyName: string,
    parameters: ReportParameter[],
    isGroupWithEqualNameExist?: boolean
  ) => {
    if (!isGroupWithEqualNameExist) {
      try {
        const payload = {
          hierarchy_name: hierarchyName,
          tdp_id: bannerId,
          user_group: groupId,
          favourite_groups: [
            {
              group_name: parameters.map(({ name }) => name).join(' & '),
              group_items: parameters.map(({ items, name }) => ({
                item_name: name,
                item_ids: items.map(({ id }) => id),
              })),
            },
          ],
        };
        const token = await getAccessTokenSilently();
        await apiRequest(
          `/users/${encodeURIComponent(user.id)}/report-params/favourites`,
          'PUT',
          token,
          payload
        );
        posthogEvent(reportBuilderPinGroupSuccess, {
          hierarchyName,
        });
      } catch (error) {
        posthogEvent(reportBuilderPinGroupFailure);
        updateModal({
          type: 'error',
          title: 'Something went wrong',
          body: 'There was an issue pinning favourite. Please try again.',
        });
      } finally {
        await refetchFavourites();
      }
    } else {
      updateModal({
        type: 'warning',
        title: 'Group cannot be pinned',
        body: 'You cannot pin this group because a group with the same name already exists.',
      });
    }
  };

  const submitReportWithEnter = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (
      !submitting &&
      isReportValid &&
      nameInputRef.current === document.activeElement &&
      event.key === 'Enter'
    ) {
      submitReport();
    }
  };

  return (
    <div className="submit-report-summary">
      <div className="submit-report-summary-parameters">
        <ParameterSummary
          editParameter={editParameter}
          overlap={overlap}
          parameters={reportParameters}
          removeParameter={removeParameter}
          showSubmit={showSubmit}
          analyticEngineParams={analyticEngineParams}
          pinGroup={pinGroup}
          deleteFavourite={deleteFavourite}
          favouriteGroups={favouriteGroups}
        />
      </div>
      <div className="submit-report-summary-info">
        <div className="submit-container" onKeyUp={submitReportWithEnter}>
          <InlineNotification
            className="report-requirement-container"
            kind={isReportValid ? 'success' : 'warning'}
            lowContrast
            hideCloseButton
            title="Minimum requirements"
          >
            <div>
              {requirements.map(({ key, valid, message }) => {
                return (
                  <div key={key} className="report-requirement">
                    <div>{valid ? <Checkmark /> : <Crossmark />}</div>
                    <div>{message}</div>
                  </div>
                );
              })}
            </div>
          </InlineNotification>
          <InlineNotification
            className="report-restrictions-notification"
            subtitle="Please note that unsupported characters (') in parameter names will be automatically removed in the report output."
            hideCloseButton
            lowContrast
            kind="info"
          />
          <TextInput
            id="report-name-input"
            data-testid="report-name-input"
            type="text"
            labelText="Capture report name"
            value={reportName}
            placeholder="Report name"
            onChange={(e) => setReportName(e.target.value)}
            ref={nameInputRef}
          />
          <div className="report-actions">
            <Tooltip align="top" label="Clear form information">
              <Button kind="tertiary" onClick={resetReport}>
                Reset
              </Button>
            </Tooltip>
            <Tooltip align="top" label="Submit report for processing">
              <Button
                disabled={submitting || !isReportValid}
                onClick={submitReport}
                data-testid="report-actions-button"
                renderIcon={submitting ? InlineLoading : null}
                className={`${submitting ? 'has-icon' : ''}`}
              >
                {submitting ? 'Submitting...' : 'Submit'}
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportSummary;
