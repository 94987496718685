import kpi_tree from '../modules_frames/kpi-tree.svg';
import kpi from '../modules_frames/kpi.svg';
import ckpi from '../modules_frames/ckpi.svg';
import custProf from '../modules_frames/custProf.svg';
import pas from '../modules_frames/pas.svg';
import xover from '../modules_frames/xover.svg';
import asrt_rank from '../modules_frames/asrt_rank.svg';
import dendro from '../modules_frames/dendro.svg';
import bskt_szbnd from '../modules_frames/bskt-szbnd.svg';
import rpb from '../modules_frames/rpb.svg';
import trrp from '../modules_frames/trrp.svg';
import delist_transf from '../modules_frames/delist-transf.svg';
import cdt from '../modules_frames/cdt.svg';
import gla from '../modules_frames/gla.svg';
import attog from '../modules_frames/attog.svg';
import dim_att from '../modules_frames/dim-att.svg';
import promo_perf from '../modules_frames/promo-perf.svg';
import cpr from '../modules_frames/cpr.svg';
import UnderConstruction from '../modules_frames/Under_Construction.svg';

const images = {
  kpi_tree,
  ckpi,
  kpi,
  custProf,
  pas,
  xover,
  asrt_rank,
  dendro,
  bskt_szbnd,
  rpb,
  trrp,
  delist_transf,
  cdt,
  gla,
  attog,
  dim_att,
  promo_perf,
  cpr,
  UnderConstruction,
};

export default images;
