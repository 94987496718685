import { useContext, useState } from 'react';
import { Button, RadioButton, RadioButtonGroup } from '@carbon/react';
import { ChevronRight } from '@carbon/icons-react';
import { OptimiserFormContext } from '../../providers/OptimiserFormProvider';
import { OptimiserMode } from '../../reducers/OptimiserFormReducer';

const ModeSelection = () => {
  const { updateMode } = useContext(OptimiserFormContext);

  const [modeInput, setModeInput] = useState<OptimiserMode>(OptimiserMode.Plan);

  return (
    <div className="OptimiserLayout">
      <div className="OptimiserMode">
        <RadioButtonGroup
          name="optimiser-mode-radio-button-group"
          orientation="vertical"
          valueSelected={modeInput}
          legendText="Do you want to range by plan size?"
          onChange={setModeInput}
        >
          <RadioButton labelText="Yes" value={OptimiserMode.Plan} />
          <RadioButton labelText="No" value={OptimiserMode.NonPlan} />
        </RadioButtonGroup>
      </div>

      <div className="OptimiserButtonBar">
        <Button
          className="has-icon"
          renderIcon={ChevronRight}
          size="sm"
          onClick={() => updateMode(modeInput)}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default ModeSelection;
