/* eslint-disable indent */
import { useNavigate } from 'react-router-dom';
import { Button, Search, Dropdown } from '@carbon/react';
import { GroupResource, Upload, TrashCan } from '@carbon/icons-react';
import { DateTime } from 'luxon';
import { useContext, useEffect, useState } from 'react';
import { useDebounce } from '../../../utils/hooks';
import { CustomGroupsContext } from '../../../providers/CustomGroupsProvider';
import { useAuth0 } from '@auth0/auth0-react';
import apiRequest from '../../../api';
import { AppContext } from '../../../providers/AppProvider';
import Fetch from '../../Fetch';
import { ModalContext } from '../../../providers/ModalProvider';
import { CACHE_KEY } from '../../../constants/api';
import usePosthog from '../../../utils/posthog';
import {
  custGroupsUploadBtnClick,
  customGroupDropdownSelection,
  deleteExistingCustGroupFailure,
  deleteExistingCustGroupSuccess,
  searchCustomGroupClick,
  updateCustomGroupFailure,
  updateCustomGroupSuccess,
} from '../../../constants/posthog';
import {
  CUSTOM_GROUP_ALL_GROUPS,
  CustomGroupAccess,
} from '../../../constants/metadata';
import { capitalizeFirstLetter } from '../../../utils/reportUtils';
import CustomGroupItem from './CustomGroupItem';
import NoCustomGroupsPlaceholder from '../NoCustomGroupsPlaceholder/NoCustomGroupsPlaceholder';
import '../../../styles/components/CustomGroups.scss';

export const renderCustomGroupsMetadata = (group) => {
  if (!group.created_timestamp) {
    return null;
  }

  return (
    <div>
      <div className="CustomGroups__item--info">
        <div>
          {`Created: ${DateTime.fromISO(group.created_timestamp).toFormat(
            'dd/LL/yyyy'
          )}`}

          {group.updated_timestamp && (
            <>
              <span className="CustomGroups__item--divider">|</span>
              {`Last Change: ${DateTime.fromISO(
                group.updated_timestamp
              ).toFormat('dd/LL/yyyy')}`}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const CustomGroups = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { bannerId, groupId } = useContext(AppContext);
  const {
    existingCustGroups,
    updateFileSpecs,
    updateUploadStep,
    updateUploadData,
    updateExistingCustGroups,
    updateSelectedCustomGroupId,
  } = useContext(CustomGroupsContext);
  const posthogEvent = usePosthog();
  const { toggleModal, updateModal } = useContext(ModalContext);
  const [filteredCustGroups, setFilteredCustGroups] = useState([]);
  const [deletingItems, setDeletingItems] = useState([]);
  const [updatingItem, setUpdatingItem] = useState('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [invalidSearchTerm, setInvalidSearchTerm] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(CUSTOM_GROUP_ALL_GROUPS);
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 500);

  useEffect(() => {
    updateUploadStep(0);
    updateFileSpecs([]);
    updateUploadData([]);
    return () => {
      updateSelectedCustomGroupId('');
    };
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm) {
      const matchedGroups = filteredCustGroups?.filter((gr) =>
        gr.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
      );
      setFilteredCustGroups(matchedGroups);
      setInvalidSearchTerm(matchedGroups.length === 0);
      posthogEvent(searchCustomGroupClick, {
        searchValue: debouncedSearchTerm,
      });
    } else {
      dropdownValue !== CUSTOM_GROUP_ALL_GROUPS
        ? setFilteredCustGroups(
            existingCustGroups.filter(
              (gr) => gr.dimension === dropdownValue.toLowerCase()
            )
          )
        : setFilteredCustGroups(existingCustGroups);
      setInvalidSearchTerm(false);
    }
  }, [debouncedSearchTerm]);

  const handleUploadClick = () => {
    posthogEvent(custGroupsUploadBtnClick);
    navigate('upload');
  };
  const deleteCustomGroup = async (customGroupIds: string[]) => {
    const itemPlural = customGroupIds.length === 1 ? '' : 's';
    const groupText = itemPlural ? 'these' : 'this';
    updateModal({
      type: 'warning',
      title: `Are you sure you want to delete ${groupText} group${itemPlural}?`,
      body: `All users with access to ${groupText} group${itemPlural} will no longer be able to use it`,
      primaryCTAText: `Delete Group${itemPlural}`,
      secondaryCTAText: 'Cancel',
      onPrimaryCTAClick: async () => {
        toggleModal(false);
        setDeletingItems(customGroupIds);

        const token = await getAccessTokenSilently();
        const payload = {
          ids: customGroupIds,
        };
        try {
          await apiRequest(
            `/configs/${bannerId}/user-groups/${groupId}/hierarchies`,
            'DELETE',
            token,
            payload
          );
          posthogEvent(deleteExistingCustGroupSuccess, {
            customGroupIds,
          });
        } catch {
          posthogEvent(deleteExistingCustGroupFailure, {
            customGroupIds,
          });
          updateModal({
            type: 'error',
            title: 'Something went wrong',
            body: 'There was an error deleting custom groups. Please try refreshing your browser and try again. If the issue persists, please contact the helpdesk.',
          });
        } finally {
          setDeletingItems([]);
          const deletedGroups = existingCustGroups.filter(
            (gr) => !customGroupIds.includes(gr.id)
          );
          updateExistingCustGroups(deletedGroups);
          filteredCustGroups.length > 0 &&
            setFilteredCustGroups(
              deletedGroups.filter(
                (gr) => gr.dimension === dropdownValue.toLowerCase()
              )
            );
        }
      },
    });
  };
  useEffect(() => {
    dropdownValue !== CUSTOM_GROUP_ALL_GROUPS
      ? setFilteredCustGroups(
          existingCustGroups.filter(
            (gr) => gr.dimension === dropdownValue.toLowerCase()
          )
        )
      : setFilteredCustGroups(existingCustGroups);
  }, [existingCustGroups, searchTerm]);

  const updateCustomGroup = async (
    customGroupId: string,
    targetValue: string,
    currentAccess?: string
  ) => {
    const newAccess =
      currentAccess === CustomGroupAccess.PUBLIC
        ? CustomGroupAccess.PRIVATE
        : CustomGroupAccess.PUBLIC;
    const updatedGroupProperty = currentAccess ? 'access' : 'name';
    const updatedPropertyValue = currentAccess ? newAccess : targetValue;

    const updateGroup = async () => {
      setUpdatingItem(customGroupId);

      const token = await getAccessTokenSilently();
      const payload = currentAccess
        ? { access: newAccess }
        : { group_name: targetValue };

      try {
        await apiRequest(
          `/configs/${bannerId}/user-groups/${groupId}/hierarchies/${customGroupId}`,
          'PATCH',
          token,
          payload
        );
        posthogEvent(updateCustomGroupSuccess, payload);
      } catch {
        posthogEvent(updateCustomGroupFailure, payload);
        updateModal({
          type: 'error',
          title: 'Something went wrong',
          body: 'There was an error updating custom groups. Please try refreshing your browser and try again. If the issue persists, please contact the helpdesk.',
        });
      } finally {
        setUpdatingItem('');
        const updatedGroups = existingCustGroups.map((group) =>
          group.id === customGroupId
            ? { ...group, [updatedGroupProperty]: updatedPropertyValue }
            : group
        );
        updateExistingCustGroups(updatedGroups);
        setSearchTerm('');
      }
    };

    if (currentAccess) {
      updateModal({
        type: 'warning',
        title: `Are you sure you want to make this custom group ${newAccess}?`,
        body: `All users in your group will ${
          newAccess === CustomGroupAccess.PRIVATE ? 'not' : ''
        } have view access to this group.`,
        primaryCTAText: `Make ${newAccess}`,
        secondaryCTAText: 'Cancel',
        onPrimaryCTAClick: async () => {
          toggleModal(false);
          await updateGroup();
        },
        onSecondaryCTAClick: () => toggleModal(false),
      });
    } else {
      await updateGroup();
    }
  };

  const dimensionTypes = [
    CUSTOM_GROUP_ALL_GROUPS,
    ...new Set(
      existingCustGroups.map((item) => capitalizeFirstLetter(item.dimension))
    ),
  ];

  return (
    <div className="CustomGroups">
      <Fetch
        alwaysFetchOnMount
        key="custom-groups"
        apiUrl={`/configs/${bannerId}/user-groups/${groupId}/hierarchies?view=custom`}
        initialData={null}
        loadingMessage="Loading all custom groups..."
        cacheKey={CACHE_KEY.CUSTOM_GROUPS}
        onReceiveData={(data) => {
          if (data) {
            updateExistingCustGroups(
              data.map((item) => ({ ...item, checked: false }))
            );
          }
        }}
      >
        <div className="CustomGroups__title-section">
          <div className="CustomGroups__title-wrapper">
            <div className="CustomGroups__icon-wrapper">
              <GroupResource size={24} />
            </div>
            <div className="CustomGroups__title">
              <div className="CustomGroups__label-wrapper">
                <span className="CustomGroups__label">Custom Groups</span>
                <span>(Product or Location)</span>
              </div>
              <div>
                Custom groups can be added as a Group, Filter, or Universe in
                the Report Builder
              </div>
            </div>
          </div>
          {existingCustGroups.length > 0 && (
            <Button
              kind="primary"
              size="md"
              renderIcon={Upload}
              className="has-icon"
              onClick={() => handleUploadClick()}
              data-testid="upload-group-btn"
            >
              Upload Group
            </Button>
          )}
        </div>
        <div className="CustomGroups__section">
          {existingCustGroups.length > 0 ? (
            <>
              <div className="CustomGroups__section-title">
                My Uploaded Groups
              </div>
              {existingCustGroups.length > 0 && (
                <div className="CustomGroups__filter-wrapper">
                  <div className="CustomGroups__filter">
                    <Search
                      id="custom-groups-search"
                      data-testid="custom-groups-search"
                      placeholder="Search input text"
                      labelText="Search"
                      type="text"
                      value={searchTerm}
                      onChange={({ target: { value } }) => setSearchTerm(value)}
                    />
                    <Dropdown
                      id="custom-groups-dropdown"
                      label="label"
                      items={dimensionTypes}
                      initialSelectedItem={dimensionTypes[0]}
                      onChange={({ selectedItem }) => {
                        posthogEvent(customGroupDropdownSelection, {
                          selectedItem,
                        });
                        setSearchTerm('');
                        selectedItem !== CUSTOM_GROUP_ALL_GROUPS
                          ? setFilteredCustGroups(
                              existingCustGroups.filter(
                                (gr) =>
                                  gr.dimension === selectedItem.toLowerCase()
                              )
                            )
                          : setFilteredCustGroups(existingCustGroups);

                        setDropdownValue(selectedItem);
                      }}
                    />
                  </div>
                  {filteredCustGroups?.some((gr) => gr.checked) && (
                    <Button
                      className="has-icon"
                      renderIcon={TrashCan}
                      kind="secondary"
                      size="sm"
                      onClick={() => {
                        const checkedIds = filteredCustGroups.reduce(
                          (acc, item) =>
                            item.checked ? [...acc, item.id] : acc,
                          []
                        );
                        deleteCustomGroup(checkedIds);
                      }}
                    >
                      Delete
                    </Button>
                  )}
                </div>
              )}
              {!invalidSearchTerm ? (
                filteredCustGroups?.map((group) => (
                  <CustomGroupItem
                    key={group.id}
                    group={group}
                    updateCustomGroup={updateCustomGroup}
                    deletingItems={deletingItems}
                    filteredCustGroups={filteredCustGroups}
                    setFilteredCustGroups={setFilteredCustGroups}
                    updatingItem={updatingItem}
                    deleteCustomGroup={deleteCustomGroup}
                  />
                ))
              ) : (
                <div className="body-emphasis-03">
                  No results found with “{debouncedSearchTerm}”. Please try
                  another search.
                </div>
              )}
            </>
          ) : (
            <NoCustomGroupsPlaceholder />
          )}
        </div>
      </Fetch>
    </div>
  );
};

export default CustomGroups;
