import type {
  FunctionComponent,
  ReactElement,
  ReactNode,
  SVGProps,
} from 'react';
import { Button, InlineNotification } from '@carbon/react';
import '../../styles/components/NoDataPlaceholder.scss';

interface NoDataPlaceholderProps {
  title: string;
  description?: {
    info: ReactNode;
    list?: ReactNode[];
  };
  icon: (props: { size?: number } & SVGProps<SVGElement>) => ReactElement;
  notificationText?: string;
  buttonLabel?: string;
  buttonIcon?: () => ReactElement;
  onClick?: () => void;
  extras?: JSX.Element;
}

const NoDataPlaceholder: FunctionComponent<NoDataPlaceholderProps> = ({
  title,
  description,
  icon: Icon,
  notificationText,
  buttonLabel,
  buttonIcon: ButtonIcon,
  onClick,
  extras,
}) => {
  return (
    <div className="NoDataPlaceholder" data-testid="no-data-placeholder">
      <Icon size={24} />
      <h4 className="NoDataPlaceholder__title">{title}</h4>
      <div className="NoDataPlaceholder__description">
        <div>{description?.info}</div>
        {description?.list && (
          <ul>
            {description.list.map((item) => (
              <li key={item.toString()}>{item}</li>
            ))}
          </ul>
        )}
      </div>
      {notificationText && (
        <InlineNotification
          kind="info"
          lowContrast
          hideCloseButton
          title={notificationText}
        />
      )}
      {buttonLabel && (
        <Button onClick={onClick}>
          {buttonLabel} <ButtonIcon />
        </Button>
      )}
      {extras && <div>{extras}</div>}
    </div>
  );
};

export default NoDataPlaceholder;
