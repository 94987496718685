/* eslint-disable indent */
import { useContext, useEffect, useMemo } from 'react';
import { Button, Search, Tag } from '@carbon/react';
import {
  Information,
  Upload,
  UserFollow,
  Filter,
  FilterRemove,
} from '@carbon/icons-react';
import NoDataPlaceholder from '../../ReportTable/NoDataPlaceholder';
import UsersTable from './UsersTable';
import { ModalContext } from '../../../providers/ModalProvider';
import InviteTeamMemberModal from './InviteTeamMemberModal';
import Fetch from '../../Fetch';
import { CACHE_KEY } from '../../../constants/api';
import { AdminPanelContext } from '../../../providers/AdminPanelProvider';
import type {
  UserData,
  UserDataConfigResponse,
  UsersTableFilters,
} from '../../../reducers/AdminPanelReducer';
import { AppContext } from '../../../providers/AppProvider';
import type { FilterConfig } from '../../FiltersContent';
import FiltersContent from '../../FiltersContent';
import '../../../styles/components/filtersPopover.scss';
import Popover from '../../Popover';
import {
  getFilterList,
  getFormattedUserMeasureAccess,
  getFormattedUserRole,
  getFormattedUserStatus,
  getFormattedUserType,
  isRowMatchCondition,
} from '../../../utils/adminPanelUtils';
import BulkUserInviteModal from './BulkUserInviteModal';

const UsersPanel = () => {
  const { queryCache, clearCacheForKey } = useContext(AppContext);
  const {
    users,
    dataConfig,
    usersTableFilters,
    updateUsersList,
    updateConfigData,
    updateUsersTableFilters,
    clearUsersTableFilters,
  } = useContext(AdminPanelContext);
  const { renderComponent } = useContext(ModalContext);
  const filtersList = getFilterList(usersTableFilters, dataConfig?.banners);
  const filteredData = users.filter((row) =>
    isRowMatchCondition(row, usersTableFilters)
  );
  const isTableEmpty = filteredData.length === 0 && users.length > 0;

  const inviteTeamMembers = (type: 'single' | 'bulk') => {
    if (type === 'single') {
      renderComponent(
        <InviteTeamMemberModal dataConfig={dataConfig} />,
        'InviteTeamMemberModal__container'
      );
    }
    if (type === 'bulk') {
      renderComponent(
        <BulkUserInviteModal dataConfig={dataConfig} />,
        'BulkUserInviteModal__container'
      );
    }
  };

  const changeFilterValue = (
    option: keyof UsersTableFilters,
    checked: boolean,
    value: string
  ) => {
    const currentValues = usersTableFilters[option] as string[];
    const newValues = checked
      ? [...currentValues, value]
      : currentValues.filter((currentValue) => currentValue !== value);

    updateUsersTableFilters(option, newValues);
  };

  const handleCloseFilterTag = (
    option: keyof UsersTableFilters,
    value: string
  ) => {
    changeFilterValue(option, false, value);
  };

  const filterConfig = (): FilterConfig[] => {
    if (!dataConfig) {
      return [];
    }
    const {
      userTypes = [],
      userRoles = [],
      userStatuses = [],
      banners = [],
      measureRestrictions = [],
    } = dataConfig;

    return [
      {
        title: 'User Type',
        filterKey: 'user_type',
        options: userTypes.map(({ type }) => ({
          value: type,
          label: getFormattedUserType(type),
        })),
      },
      {
        title: 'Role',
        filterKey: 'user_role',
        options: userRoles.map(({ role }) => ({
          value: role,
          label: getFormattedUserRole(role),
        })),
      },
      {
        title: 'Measure Access',
        filterKey: 'measure_access',
        options: measureRestrictions.map(({ id }) => ({
          value: id,
          label: getFormattedUserMeasureAccess(id),
        })),
      },
      {
        title: 'Banner Access',
        filterKey: 'banners',
        options: banners.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
      },
      {
        title: 'Status',
        filterKey: 'status',
        options: userStatuses.map(({ status }) => ({
          value: status,
          label: (
            <Tag
              size="sm"
              className={`UsersTableRow__status UsersTableRow__status--${status}`}
            >
              {getFormattedUserStatus(status)}
            </Tag>
          ),
        })),
      },
    ];
  };

  const isFilterSelectionEmpty = useMemo(() => {
    return Object.values(usersTableFilters).every(
      (value) => value?.length === 0
    );
  }, [usersTableFilters]);

  useEffect(() => {
    return () => {
      clearCacheForKey(CACHE_KEY.ADMIN_PANEL_CONFIG);
    };
  }, []);

  return (
    <Fetch
      apiUrl="/admin/configs"
      initialData={null}
      onReceiveData={(data: UserDataConfigResponse) => {
        if (data) {
          updateConfigData(data);
        }
      }}
      cacheKey={CACHE_KEY.ADMIN_PANEL_CONFIG}
      alwaysFetchOnMount={
        !dataConfig || !queryCache[CACHE_KEY.ADMIN_PANEL_CONFIG]
      }
      hideChildrenUntilFetched
      loadingMessage="Loading configuration data..."
    >
      <Fetch
        apiUrl="/admin/users"
        initialData={users}
        onReceiveData={(data: UserData[]) => {
          if (data) {
            updateUsersList(data);
          }
        }}
        cacheKey={CACHE_KEY.USER_LIST}
        alwaysFetchOnMount={users?.length === 0}
        hideChildrenUntilFetched={users?.length === 0}
        loadingMessage="Loading users..."
      >
        <div className="UsersPanel__invite-user-buttons">
          <Button
            kind="secondary"
            size="md"
            onClick={() => inviteTeamMembers('bulk')}
          >
            Upload Team Mates <Upload />
          </Button>
          <Button size="md" onClick={() => inviteTeamMembers('single')}>
            Invite Team Mate <UserFollow />
          </Button>
        </div>
        <div className="UsersPanel__container">
          {users?.length > 0 ? (
            <>
              <div className="UsersPanel__filters">
                <Search
                  placeholder="Search user"
                  labelText=""
                  data-testid="report-name-filter-input"
                  className="report-name-filter-input-container"
                  value={usersTableFilters.name ?? ''}
                  onChange={(e) =>
                    updateUsersTableFilters('name', e.target.value)
                  }
                />
                <Popover
                  align="bottom-right"
                  caret={false}
                  dropShadow
                  className="FiltersPopover"
                  target={
                    <Button
                      size="md"
                      kind="tertiary"
                      renderIcon={Filter}
                      className="FiltersContent__target"
                      data-testid="admin-panel-filters-btn"
                    >
                      Filters
                    </Button>
                  }
                >
                  <FiltersContent
                    filterState={usersTableFilters}
                    filterConfig={filterConfig()}
                    changeFilterValue={changeFilterValue}
                    isClearButtonVisible={!isFilterSelectionEmpty}
                    clearAllFilters={clearUsersTableFilters}
                  />
                </Popover>
              </div>
              {filtersList.length > 0 && (
                <div className="FiltersPopover__options-list">
                  <div className="FiltersPopover__option-title">
                    Other filters:
                  </div>
                  <div className="FiltersPopover__selected-options">
                    {filtersList.map(({ key, value, label }) => (
                      <Tag
                        key={value}
                        filter
                        data-testid={`filter-tag-${value}`}
                        onClose={() =>
                          handleCloseFilterTag(key, value as string)
                        }
                      >
                        {label}
                      </Tag>
                    ))}
                  </div>
                </div>
              )}
              {!isTableEmpty && (
                <UsersTable rowData={users} filteredData={filteredData} />
              )}
              {isTableEmpty && (
                <NoDataPlaceholder
                  title="No Users Found"
                  icon={Information}
                  description={{
                    info: 'Please refine your filters and try again.',
                  }}
                  buttonLabel="Clear Filters"
                  onClick={clearUsersTableFilters}
                  buttonIcon={FilterRemove}
                />
              )}
            </>
          ) : (
            <div className="UsersPanel__no-data-placeholder">
              <NoDataPlaceholder
                title="No Users Added Yet"
                icon={Information}
                description={{
                  info: 'To get started, you can invite team mates in bulk or one by one using the buttons below.',
                }}
                extras={
                  <div className="flex gap--large">
                    <Button kind="secondary" size="md">
                      Upload Team Mates <Upload />
                    </Button>
                    <Button
                      size="md"
                      onClick={() => inviteTeamMembers('single')}
                    >
                      Invite Team Mate <UserFollow />
                    </Button>
                  </div>
                }
              />
            </div>
          )}
        </div>
      </Fetch>
    </Fetch>
  );
};

export default UsersPanel;
