import type {
  AuthenticationType,
  USER_ROLE,
  USER_TYPE,
} from '../constants/metadata';
import {
  CLEAR_USERS_TABLE_FILTERS,
  UPDATE_ADMIN_PANEL_USERS_LIST,
  UPDATE_CONFIG_DATA,
  UPDATE_USERS_TABLE_FILTERS,
} from '../constants/reducers';

export enum USER_STATUS {
  ACTIVE = 'active',
  DISABLED = 'disabled',
  PENDING = 'pending',
}

export interface BannerData {
  id: number;
  name: string;
  text_colour?: string;
  background_colour?: string;
  user_groups: {
    id: number;
    name: string;
  }[];
}

export type MeasureAccessValue = 0 | 1;

export interface UserData {
  id: string;
  name: string;
  banners: BannerData[];
  user_role: USER_ROLE;
  user_role_description: string;
  user_type: USER_TYPE;
  measure_access: MeasureAccessValue;
  status: USER_STATUS;
  authentication?: AuthenticationType;
  department?: string;
  job_title?: string;
  supplier_name?: string;
}

interface PayloadUserGroupData {
  id: number;
}

interface PayloadBannerData {
  id: number;
  user_groups: PayloadUserGroupData[];
}

export interface PayloadUserData {
  name: string;
  email: string;
  banners: PayloadBannerData[];
  user_role: USER_ROLE;
  user_type: USER_TYPE;
  measure_access: MeasureAccessValue;
  authentication?: AuthenticationType;
  department?: string;
  job_title?: string;
  supplier_name?: string;
}

export interface UserRoleData {
  name: string;
  role: USER_ROLE;
  description?: string;
}

export interface UserTypeData {
  name: string;
  type: USER_TYPE;
}

export interface UserAuthData {
  name: string;
  type: AuthenticationType;
}

export interface UserStatusData {
  name: string;
  status: USER_STATUS;
}

export interface UserMeasureAccessData {
  id: MeasureAccessValue;
  name: string;
}

export interface UserDataConfigResponse {
  banners: BannerData[];
  measure_restrictions: UserMeasureAccessData[];
  user_roles: UserRoleData[];
  user_types: UserTypeData[];
  user_statuses: UserStatusData[];
}

export interface UserDataConfig {
  banners: BannerData[];
  measureRestrictions: UserMeasureAccessData[];
  userRoles: UserRoleData[];
  userTypes: UserTypeData[];
  authTypes: UserAuthData[];
  userStatuses: UserStatusData[];
}

export type UsersTableFilters = Record<
  keyof Pick<
    UserData,
    | 'name'
    | 'user_type'
    | 'user_role'
    | 'authentication'
    | 'banners'
    | 'measure_access'
    | 'status'
  >,
  string | string[] | number[]
>;

export interface AdminPanelState {
  users: UserData[];
  dataConfig: UserDataConfig;
  usersTableFilters: UsersTableFilters;
}

interface UpdateUserList {
  type: typeof UPDATE_ADMIN_PANEL_USERS_LIST;
  users: UserData[];
}

interface UpdateConfigData {
  type: typeof UPDATE_CONFIG_DATA;
  dataConfig: UserDataConfigResponse;
}

interface UpdateUsersTableFilters {
  type: typeof UPDATE_USERS_TABLE_FILTERS;
  key: keyof UsersTableFilters;
  value: string | string[];
}

interface ClearUsersTableFilters {
  type: typeof CLEAR_USERS_TABLE_FILTERS;
}

export type AdminPanelAction =
  | UpdateUserList
  | UpdateConfigData
  | UpdateUsersTableFilters
  | ClearUsersTableFilters;

export const adminPanelInitialState: AdminPanelState = {
  users: [],
  dataConfig: null,
  usersTableFilters: {
    name: '',
    user_type: [],
    user_role: [],
    authentication: [],
    banners: [],
    measure_access: [],
    status: [],
  },
};

export const adminPanelReducer = (
  state: AdminPanelState,
  action: AdminPanelAction
): AdminPanelState => {
  switch (action.type) {
    case UPDATE_ADMIN_PANEL_USERS_LIST: {
      return {
        ...state,
        users: action.users,
      };
    }
    case UPDATE_CONFIG_DATA: {
      const {
        banners,
        measure_restrictions,
        user_roles,
        user_types,
        user_statuses,
      } = action.dataConfig;
      return {
        ...state,
        dataConfig: {
          ...state.dataConfig,
          banners,
          measureRestrictions: measure_restrictions,
          userRoles: user_roles,
          userTypes: user_types,
          userStatuses: user_statuses,
        },
      };
    }
    case UPDATE_USERS_TABLE_FILTERS:
      return {
        ...state,
        usersTableFilters: {
          ...state.usersTableFilters,
          [action.key]: action.value,
        },
      };
    case CLEAR_USERS_TABLE_FILTERS:
      return {
        ...state,
        usersTableFilters: {
          ...adminPanelInitialState.usersTableFilters,
        },
      };
    default:
      return state;
  }
};
