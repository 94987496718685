import type { FunctionComponent } from 'react';
import { Button, InlineLoading } from '@carbon/react';

interface Action {
  name: string;
  onClick: () => void;
  loading?: boolean;
  disabled?: boolean;
  kind?:
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'ghost'
    | 'danger--primary'
    | 'danger--ghost'
    | 'danger--tertiary'
    | 'tertiary';
  icon?: JSX.Element;
  dataTestId?: string;
}

interface SidePanelActionsProps {
  actions: Action[];
}

const SidePanelActions: FunctionComponent<SidePanelActionsProps> = ({
  actions,
}) => {
  return (
    <>
      <div className="SidePanelActions__spacer" />
      <div className="SidePanelActions">
        {actions.map((action) => {
          const { name, kind, disabled, loading, icon, onClick, dataTestId } =
            action;
          return (
            <Button
              key={name}
              className={icon ? 'has-icon' : ''}
              type="submit"
              kind={kind ? kind : 'primary'}
              disabled={disabled}
              onClick={onClick}
              renderIcon={loading ? InlineLoading : icon}
              data-testid={dataTestId}
            >
              {name}
            </Button>
          );
        })}
      </div>
    </>
  );
};

export default SidePanelActions;
