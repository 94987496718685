import type { FunctionComponent, MouseEventHandler } from 'react';
import { useContext } from 'react';
import { Tooltip } from '@carbon/react';
import { AppContext } from '../../providers/AppProvider';

type Props = {
  title: string;
  onClearParams: MouseEventHandler<HTMLDivElement>;
  parameterKey: string;
};

const ParameterTitle: FunctionComponent<Props> = (props) => {
  const { title, onClearParams, parameterKey } = props;
  const { reportParameters } = useContext(AppContext);

  return (
    <div className="parameter-type text-secondary">
      <div>{title}</div>
      {reportParameters[parameterKey].length > 0 && (
        <Tooltip align="top-right" label="Remove all selected">
          <div
            className="clear-all clickable hoverable"
            data-testid={`clear-${title.toLowerCase()}-btn`}
            onClick={onClearParams}
          >
            Clear all
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default ParameterTitle;
