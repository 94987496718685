import type { FunctionComponent } from 'react';
import CategoryHierarchyNode from './CategoryHierarchyNode';

export interface SelectionsObject {
  id: string;
  label: string;
}

interface HierarchyTreeProps {
  nodes: HierNode[];
  searchTerm: string;
  nodeLevel?: number;
  parentNodes?: HierNode[];
  selectedItemPath: string[];
  handleItemClick: (items: HierNode[]) => void;
}

const CategoryHierarchyTree: FunctionComponent<HierarchyTreeProps> = ({
  nodes,
  searchTerm,
  nodeLevel,
  parentNodes,
  selectedItemPath,
  handleItemClick,
}) => {
  return (
    <>
      {nodes &&
        nodes.map((node) => {
          return (
            <CategoryHierarchyNode
              key={node.id}
              node={node}
              searchTerm={searchTerm}
              parentNodes={parentNodes}
              nodeLevel={nodeLevel}
              handleItemClick={handleItemClick}
              selectedItemPath={selectedItemPath}
            />
          );
        })}
    </>
  );
};

export default CategoryHierarchyTree;
