/* eslint-disable indent */
import type { FunctionComponent } from 'react';
import { useContext } from 'react';
import { AssortmentContext } from '../../providers/AssortmentProvider';
import { ReportContext } from '../../providers/ReportProvider';
import { Button } from '@carbon/react';
import { Reset } from '@carbon/icons-react';
import DataGrid, { CustomActionKey } from './DataGrid';
import type { VisualData } from '../../reducers/ReportReducer';
import { VisualType } from '../../reducers/ReportReducer';
import { DEFAULT_PRODUCT_SUMMARY } from '../../constants/assortmentFileSync';
import type { SummaryCard } from '../Optimise/SummaryCards';
import { OptimiserMode } from '../../reducers/OptimiserFormReducer';
import { omit } from '../../utils/object';
import { getExtendedHeaders } from '../../utils/CDTUtils';
import type { AssortmentData } from '../../reducers/AssortmentReducer';
import { findVisualByType } from '../../utils/reportUtils';

interface OptimiserGridProps {
  isAuthor?: boolean;
  isSupplier?: boolean;
  dropdownKey: string;
  reportTemplateId: string;
  reportTemplateIndex: number;
  mode: OptimiserMode;
}

const getConfiguration = (
  configuration: number[] | Record<string, number[]> | undefined,
  dropdownKey: string
): number[] | undefined => {
  if (!configuration) {
    return undefined;
  }

  return Array.isArray(configuration)
    ? configuration
    : configuration[dropdownKey];
};

const OptimiserGrid: FunctionComponent<OptimiserGridProps> = ({
  isAuthor,
  isSupplier,
  dropdownKey,
  reportTemplateId,
  reportTemplateIndex,
  mode,
}) => {
  const { visualsData, reportConfig, patchVisualsData, updateVisualsData } =
    useContext(ReportContext);
  const {
    optimiserId,
    optimiserValid,
    optimiserData,
    updateOptimiserRows,
    saveOptimiserData,
    saveOptimiserGrid,
    validateOptimiser,
  } = useContext(AssortmentContext);

  const onEditRows = (rows, cellKey) => {
    if (
      cellKey === 'action' &&
      JSON.stringify(rows) !== JSON.stringify(optimiserData[dropdownKey])
    ) {
      const { visuals } = reportConfig.configuration;
      validateOptimiser(false);
      const productSummaryId = findVisualByType(
        visuals[reportTemplateId],
        VisualType.PRODUCT_SUMMARY
      )?.id;
      const analysisCardsId = findVisualByType(
        visuals[reportTemplateId],
        VisualType.SUMMARY_CARDS
      )?.id;

      if (typeof productSummaryId === 'number') {
        const newSummary = {
          ...visualsData[productSummaryId],
          summary: {
            ...visualsData[productSummaryId].summary,
            [dropdownKey]: [DEFAULT_PRODUCT_SUMMARY],
          },
        };

        updateVisualsData(productSummaryId, newSummary);
      }

      if (typeof analysisCardsId === 'number') {
        const updatedCards = visualsData[analysisCardsId].cards[
          dropdownKey
        ].map((card: SummaryCard) => ({
          ...card,
          data: { ...card.data, value: undefined },
          children: card.children.map((child) => ({
            ...child,
            value: undefined,
          })),
        }));

        const newCards = {
          ...visualsData[analysisCardsId],
          cards: {
            ...visualsData[analysisCardsId].cards,
            [dropdownKey]: updatedCards,
          },
        };

        updateVisualsData(analysisCardsId, newCards);
      }
    }

    updateOptimiserRows(rows, dropdownKey);
  };

  const optimiserVisual = visualsData[optimiserId];

  const { isSearchable, isVisible, stickyColumns, headers, rows } =
    (optimiserVisual as VisualData<AssortmentData>) ?? {};

  // TODO: To be improved after TRP-3533
  const isNestedConfiguration = !Array.isArray(isVisible);
  const columnWidths = (
    isNestedConfiguration
      ? optimiserVisual?.columnWidths?.[dropdownKey]
      : optimiserVisual?.columnWidths
  ) as Record<string, number> | undefined;

  // TODO: The editing of plan-actions is due to be implemented in the second
  // phase of assortment. As a temporary measure the edibility for plan-based
  // headers is being overridden
  const suppressedEditingHeaderData = headers[dropdownKey].map((header) =>
    mode !== OptimiserMode.Plan || !header.editable || header.key === 'notes'
      ? header
      : omit(header, ['editable'])
  );

  const extendedHeaders = getExtendedHeaders(
    suppressedEditingHeaderData,
    rows[dropdownKey]
  );

  return (
    <div className="OptimiserGrid">
      <DataGrid
        headerData={extendedHeaders}
        rowData={rows[dropdownKey]}
        stickyColumns={getConfiguration(stickyColumns, dropdownKey)}
        visibleColumns={getConfiguration(isVisible, dropdownKey)}
        searchable
        exportedFileName={`${reportConfig.report_name} - Optimisation`}
        searchableColumns={getConfiguration(isSearchable, dropdownKey)}
        columnWidths={columnWidths}
        visualData={optimiserVisual}
        id={optimiserId}
        dropdownKey={dropdownKey}
        resizable
        showPagination={false}
        reportConfig={reportConfig}
        isAuthor={isAuthor}
        isSupplier={isSupplier}
        isNestedConfiguration={isNestedConfiguration}
        customToolbarActions={
          mode !== OptimiserMode.Plan &&
          !optimiserValid &&
          isAuthor &&
          !isSupplier
            ? [
                {
                  key: CustomActionKey.OPTIMISER_SAVE_BUTTON,
                  element: (
                    <Button
                      className="OptimiserGrid__save-btn has-icon"
                      renderIcon={Reset}
                      size="md"
                      onClick={saveOptimiserData}
                    >
                      Save & Optimise
                    </Button>
                  ),
                },
              ]
            : []
        }
        patchVisualsData={patchVisualsData}
        onEditRows={onEditRows}
        onSave={optimiserValid ? saveOptimiserGrid : undefined}
        reportTemplateId={reportTemplateId}
        reportTemplateIndex={reportTemplateIndex}
      />
    </div>
  );
};

export default OptimiserGrid;
