import type { FunctionComponent } from 'react';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { Grid, Column } from '@carbon/react';
import { ReportContext } from '../../providers/ReportProvider';
import type { Visual, VisualData } from '../../reducers/ReportReducer';
import { VisualType } from '../../reducers/ReportReducer';
import ConditionalWrapper from '../ConditionalWrapper';
import { assortmentWrapper } from '../../providers/AssortmentProvider';
import { ALL_VISUALS_KEY } from '../../constants/values';
import DataVisual from '../Report/DataVisual';
import { AppContext } from '../../providers/AppProvider';
import {
  getHomepageConfigUrl,
  getHomepageFilePath,
} from '../../utils/reportUtils';
import SharedDropdowns from '../Report/Dropdowns/SharedDropdowns';
import { HomeContext } from '../../providers/HomeProvider';
import { renderNodeLabel } from '../../utils';
import { USER_TYPE } from '../../constants/metadata';
import type { TimePeriod } from '../../reducers/HomeReducer';
import { sharedDropdownWrapper } from '../../utils/chartUtils';
import { getIcon } from '../../utils/iconUtils';

interface ReportContentProps {
  visualMetaData: Visual[];
  reportTemplateId: string;
  reportTemplateIndex: number;
  visualsIndex: number;
  visualContent: number[];
}

const HomeReportContent: FunctionComponent<ReportContentProps> = ({
  visualMetaData,
  reportTemplateId,
  reportTemplateIndex,
  visualsIndex,
  visualContent,
}) => {
  const { bannerId, groupId, user } = useContext(AppContext);
  const {
    visualRefs,
    visualsData,
    fullscreenVisual,
    reportConfig,
    visualControlsRef,
    fullScreenRef,
    updateFullscreenRef,
  } = useContext(ReportContext);
  const {
    selectedCategoryKey,
    selectedCategory,
    categories,
    homeDropdownSelections,
    updateTimePeriods,
  } = useContext(HomeContext);
  const fullscreenRef = useRef<HTMLDivElement | null>(null);
  const globalSelections = useMemo(
    () => [
      {
        label: 'Product',
        selectedKey: selectedCategoryKey,
      },
    ],
    [selectedCategoryKey]
  );

  useEffect(() => {
    if (visualContent.includes(fullscreenVisual)) {
      updateFullscreenRef(fullscreenRef);
    } else if (fullscreenVisual === null && fullScreenRef) {
      updateFullscreenRef(null);
    }
  }, [fullscreenVisual]);

  const getCustomStyles = (vis: Visual) => {
    if ([VisualType.DENDROGRAM, VisualType.CDT_DENDROGRAM].includes(vis.type)) {
      return { marginRight: 0 };
    }
    if ([VisualType.DENDRO_GRID, VisualType.CDT_GRID].includes(vis.type)) {
      return { marginLeft: 0 };
    }
  };

  const visualResponsive = (vis: Visual): boolean => {
    return (
      vis.type !== VisualType.CDT_DENDROGRAM && vis.type !== VisualType.CDT_GRID
    );
  };

  const getVisualHeader = (
    visualData: VisualData,
    reportType: VisualType,
    category: HierNode
  ): VisualHeader => {
    const { title, icon } = visualData?.visualHeader ?? {};
    const isSupplier = user.user_type === USER_TYPE.SUPPLIER;
    const categoryLabel =
      !isSupplier && category.id === categories[0].id
        ? 'All Categories'
        : renderNodeLabel(category.label);

    return (
      title && {
        icon: getIcon(icon),
        title:
          reportType === VisualType.CARDS ? title : `${categoryLabel} ${title}`,
      }
    );
  };

  const getMetadataByType = (visualType: VisualType) =>
    visualMetaData.find(({ type }) => type === visualType);

  const {
    showSharedDropdownContainer,
    isCardsContainer,
    treeMetadata,
    controlsContainerRef,
    hideExportButtonForKpiTreeSummary,
  } = useMemo(() => {
    const treeMetadata = getMetadataByType(VisualType.TREE);
    const isGridContainer = !!getMetadataByType(VisualType.CELL_GRID);
    const isCardsContainer =
      getMetadataByType(VisualType.CARDS) && visualMetaData.length === 1;
    const isContributionBreakdownContainer =
      getMetadataByType(VisualType.STACKED_COLUMN) &&
      visualMetaData.length === 1;
    const hideExportButtonForKpiTreeSummary = (id: string | number) => {
      return (
        visualMetaData.length === 2 &&
        !!treeMetadata &&
        getMetadataByType(VisualType.STACKED_COLUMN).id === id
      );
    };
    const controlsContainerRef = visualControlsRef[visualMetaData[0].id];

    return {
      showSharedDropdownContainer:
        !isGridContainer ||
        ((treeMetadata || isContributionBreakdownContainer) &&
          !controlsContainerRef?.current),
      isCardsContainer,
      treeMetadata,
      controlsContainerRef,
      hideExportButtonForKpiTreeSummary,
    };
  }, [visualControlsRef, visualMetaData]);

  const containerClasses = [
    'HomeReportContent__container',
    visualMetaData.length < 2 &&
      'HomeReportContent__container--one-visual-container',
    isCardsContainer && 'HomeReportContent__container--cards-container',
    !visualMetaData.every(({ colSpan }) => colSpan === 16) &&
      'HomeReportContent__container--no-background',
  ].filter(Boolean);

  return (
    <div
      ref={(el) =>
        (visualRefs.current[
          `${ALL_VISUALS_KEY}_${reportTemplateId}_${visualsIndex}`
        ] = el)
      }
      className={containerClasses.join(' ')}
    >
      <div
        className={`fullscreen-container fullscreen-${
          fullscreenVisual !== null
        }`}
        data-testid={`fullscreen-${fullscreenVisual !== null}`}
        ref={fullscreenRef}
      >
        <ConditionalWrapper
          condition={reportConfig.report_type === 'Customer Decision Tree'}
          wrapper={assortmentWrapper}
        >
          {showSharedDropdownContainer && (
            <ConditionalWrapper
              wrapper={(children) =>
                sharedDropdownWrapper(
                  children,
                  controlsContainerRef.current,
                  'HomeReportContent__shared-dropdown-wrapper'
                )
              }
              condition={
                !!treeMetadata &&
                fullscreenVisual === null &&
                controlsContainerRef?.current
              }
            >
              <SharedDropdowns
                tab={`${reportTemplateId}_${visualsIndex}`}
                visualIds={
                  reportConfig?.configuration?.switchers?.[reportTemplateId][
                    visualsIndex
                  ]?.visualContent
                }
                globalSelections={globalSelections}
                hideLabels={!!treeMetadata}
              />
            </ConditionalWrapper>
          )}
          <Grid data-testid="visuals-grid">
            {visualMetaData
              .filter(
                ({ id }) => fullscreenVisual === null || fullscreenVisual === id
              )
              .map((vis: Visual) => {
                const isResponsive = visualResponsive(vis);
                return (
                  <Column
                    id={`visual-column-${vis.id}`}
                    data-testid={vis.apiUrl}
                    className={vis.type}
                    key={vis.id}
                    lg={vis.colSpan}
                    md={isResponsive ? 8 : vis.colSpan / 2}
                    sm={isResponsive ? 4 : vis.colSpan / 4}
                    style={getCustomStyles(vis)}
                  >
                    <DataVisual
                      {...vis}
                      tab={`${reportTemplateId}_${visualsIndex}`}
                      apiUrl={getHomepageConfigUrl(
                        vis.apiUrl,
                        bannerId,
                        groupId
                      )}
                      filePath={getHomepageFilePath(vis.apiUrl, bannerId)}
                      visualData={visualsData[vis.id]}
                      reportType={reportConfig.report_type}
                      reportTemplateId={reportTemplateId}
                      reportTemplateIndex={reportTemplateIndex}
                      isHomepage
                      globalSelections={globalSelections}
                      showExport={!hideExportButtonForKpiTreeSummary(vis.id)}
                      exportAllCharts={
                        !!treeMetadata &&
                        !hideExportButtonForKpiTreeSummary(vis.id)
                      }
                      visualHeader={getVisualHeader(
                        visualsData[vis.id],
                        vis.type,
                        selectedCategory
                      )}
                      visualsIndex={visualsIndex}
                      onReceiveVisualConfig={(time: TimePeriod[]) => {
                        updateTimePeriods(homeDropdownSelections, time);
                      }}
                    />
                  </Column>
                );
              })}
          </Grid>
        </ConditionalWrapper>
      </div>
    </div>
  );
};

export default HomeReportContent;
