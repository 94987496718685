import { useEffect, useLayoutEffect, useRef, useState } from 'react';

export const removeFixedElement = (tableHeader: HTMLElement) => {
  !!tableHeader.children[1] && tableHeader.removeChild(tableHeader.children[1]);
};

export const setScrollPosition = (
  headerElement: HTMLElement,
  tableHeader: HTMLElement,
  container: Element,
  cloneElement: Node,
  resizable?: boolean
) => {
  tableHeader.appendChild(cloneElement);
  const resizeCallback = (
    element: HTMLElement,
    cloneElement: HTMLElement,
    isClonedElement: boolean
  ) => {
    if (cloneElement.clientWidth) {
      headerElement.style.width = `${container.clientWidth}px`;
      element.style.minWidth = `${cloneElement.clientWidth}px`;
      element.style.width = `${cloneElement.clientWidth}px`;
      if (isClonedElement && (element.childNodes[1] as HTMLElement)) {
        (
          element.childNodes[1] as HTMLElement
        ).style.width = `${cloneElement.clientWidth}px`;
      }
    }
  };
  headerElement.scrollLeft = container.scrollLeft;
  if (resizable) {
    const resizeObservers = Array.from(headerElement.children).map(
      (child, index) => {
        const resizeObserver = new ResizeObserver(() =>
          resizeCallback(
            child as HTMLElement,
            cloneElement.childNodes[index] as HTMLElement,
            false
          )
        );
        const resizeObserverClonedElement = new ResizeObserver(() =>
          resizeCallback(
            cloneElement.childNodes[index] as HTMLElement,
            child as HTMLElement,
            true
          )
        );
        resizeObserver.observe(cloneElement.childNodes[index] as HTMLElement);
        resizeObserverClonedElement.observe(child);
        return [resizeObserver, resizeObserverClonedElement];
      }
    );
    return resizeObservers;
  } else {
    Array.from(headerElement.children).forEach((child, index) => {
      resizeCallback(
        child as HTMLElement,
        cloneElement.childNodes[index] as HTMLElement,
        false
      );
    });
    return [];
  }
};

export const intersectionObserverCallback = ({
  tableHeader,
  container,
  isSticky,
  setScrollPosition,
  removeFixedElement,
  resizable,
}: {
  tableHeader: HTMLElement;
  isSticky: boolean;
  container: HTMLElement;
  setScrollPosition: (
    headerElement: HTMLElement,
    tableHeader: HTMLElement,
    container: Element,
    cloneElement: Node,
    resizable?: boolean
  ) => ResizeObserver[][];
  removeFixedElement: (tableHeader: HTMLElement) => void;
  resizable?: boolean;
}) => {
  const headerElement = tableHeader.children[0] as HTMLElement;
  const cloneElement = headerElement.cloneNode(true) as HTMLElement;
  cloneElement.classList.remove('sticky-header');
  cloneElement.classList.add('visible-header');

  if (isSticky) {
    if (tableHeader.children.length < 2) {
      return (
        setScrollPosition(
          headerElement,
          tableHeader,
          container,
          cloneElement,
          resizable
        )?.flatMap((observer) => observer) ?? []
      );
    }
  } else {
    removeFixedElement(tableHeader);
  }
  return [];
};

export const useStickyTableHeader = ({
  rowsLength,
  columns,
  tableId,
  columnWidths,
  documentWidth,
  resizable,
}: {
  rowsLength?: number;
  columns?: Header[];
  tableId?: string;
  columnWidths?: { [key: string]: number };
  documentWidth?: number;
  resizable?: boolean;
}) => {
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const scrollbarRef = useRef<HTMLDivElement | null>(null);
  const tableSelector = tableId ? `#${tableId}` : '';
  const tableWrapperRef = useRef<HTMLElement | null>(null);
  const tableRef = useRef<HTMLElement | null>(null);
  const tableContainerRef = useRef<HTMLElement | null>(null);
  const tableHeaderRef = useRef<HTMLElement | null>(null);
  const [isRefsReady, setIsRefsReady] = useState(false);

  useEffect(() => {
    if (rowsLength) {
      const tableTarget = document.querySelector<HTMLElement>(
        `${tableSelector} .cds--data-table`
      );
      const tableContainer = document.querySelector<HTMLElement>(
        `${tableSelector} .cds--data-table-content`
      );
      const tableHeader = document.querySelector<HTMLElement>(
        `${tableSelector} .cds--data-table>thead`
      );
      const tableWrapper = document.querySelector<HTMLElement>(tableSelector);

      tableRef.current = tableTarget;
      tableContainerRef.current = tableContainer;
      tableHeaderRef.current = tableHeader;
      tableWrapperRef.current = tableWrapper;
      setIsRefsReady(true);
    }
    return () => {
      return setIsRefsReady(false);
    };
  }, [rowsLength, columns, columnWidths]);

  useEffect(() => {
    let tableObserver: IntersectionObserver;
    if (rowsLength > 0 && tableRef.current && isRefsReady) {
      const tableObserverCallback = (entries: IntersectionObserverEntry[]) => {
        setIsTableVisible(entries[0].isIntersecting);
      };
      tableObserver = new IntersectionObserver(tableObserverCallback, {
        rootMargin: '0px 0px -500px 0px',
      });
      tableObserver.observe(tableWrapperRef.current);
    }

    return () => {
      tableObserver?.disconnect();
    };
  }, [rowsLength, columns, columnWidths, isRefsReady]);

  useEffect(() => {
    let tableHeaderObserver: IntersectionObserver;
    if (rowsLength > 0 && tableHeaderRef.current && isRefsReady) {
      tableHeaderObserver = new IntersectionObserver(
        (entries) => {
          setIsSticky(isTableVisible && !entries[0].isIntersecting);
        },
        { rootMargin: '-100px 0px 40px 0px' }
      );
      tableHeaderObserver.observe(tableHeaderRef.current);
    }

    return () => {
      tableHeaderObserver?.disconnect();
    };
  }, [isTableVisible, isRefsReady]);

  useLayoutEffect(() => {
    let resizeObservers: ResizeObserver[] = [];
    if (isRefsReady) {
      resizeObservers =
        intersectionObserverCallback({
          tableHeader: tableHeaderRef.current,
          isSticky,
          container: tableContainerRef.current,
          setScrollPosition,
          removeFixedElement,
          resizable,
        }) ?? [];
    }

    return () => {
      if (!isSticky) {
        resizeObservers.forEach((observer) => {
          observer.disconnect();
        });
      }
    };
  }, [rowsLength, documentWidth, columnWidths, isSticky, isRefsReady]);

  useEffect(() => {
    const headerElement = tableHeaderRef.current?.children[0];
    const headerScrollCallback = () => {
      tableContainerRef.current.scrollLeft = headerElement.scrollLeft;
    };
    if (isRefsReady && isSticky) {
      headerElement?.addEventListener('scroll', headerScrollCallback);
    }
    return () => {
      headerElement?.removeEventListener('scroll', headerScrollCallback);
    };
  }, [isRefsReady, isSticky]);

  useEffect(() => {
    const headerElement = tableHeaderRef.current?.children[0] as HTMLElement;

    const headerScrollCallback = (e: UIEvent) => {
      const target = e.target as HTMLElement;
      headerElement.scrollLeft = target.scrollLeft;
    };
    const headerResizeSizeCallback = () => {
      headerElement.style.width = `${tableContainerRef.current.clientWidth}px`;
    };
    if (isSticky) {
      window.addEventListener('resize', headerResizeSizeCallback);
      tableContainerRef.current?.addEventListener(
        'scroll',
        headerScrollCallback
      );
    }
    return () => {
      tableContainerRef.current?.removeEventListener(
        'scroll',
        headerScrollCallback
      );
      window.removeEventListener('resize', headerResizeSizeCallback);
    };
  }, [isSticky, rowsLength, documentWidth, columnWidths]);

  useEffect(() => {
    const scrollbarContainer = scrollbarRef.current;
    const scrollbarContent = scrollbarRef.current
      ?.firstElementChild as HTMLElement;

    const tableScrollCallback = (e: Event) => {
      const target = e.target as HTMLElement;
      if (scrollbarContainer.scrollLeft !== target.scrollLeft) {
        scrollbarContainer.scrollLeft = target.scrollLeft;
      }
    };

    const scrollCallback = (e: Event) => {
      const target = e.target as HTMLElement;
      if (tableContainerRef.current?.scrollLeft !== target.scrollLeft) {
        tableContainerRef.current.scrollLeft = target.scrollLeft;
      }
    };

    const resizeCallback = () => {
      scrollbarContent.style.width = `${tableRef.current.clientWidth}px`;
      scrollbarContainer.scrollLeft = tableContainerRef.current.scrollLeft;
    };

    const resizeObserver = new ResizeObserver(resizeCallback);

    if (
      scrollbarContent &&
      tableContainerRef.current &&
      scrollbarContainer &&
      isRefsReady
    ) {
      resizeObserver.observe(tableContainerRef.current);
      tableContainerRef.current.addEventListener('scroll', tableScrollCallback);
      scrollbarContainer.addEventListener('scroll', scrollCallback);
    }

    return () => {
      tableContainerRef.current?.removeEventListener(
        'scroll',
        tableScrollCallback
      );
      scrollbarContainer?.removeEventListener('scroll', scrollCallback);
      tableContainerRef.current &&
        resizeObserver.unobserve(tableContainerRef.current);
    };
  }, [rowsLength, columns, columnWidths, isRefsReady, isSticky]);

  return {
    isSticky,
    scrollbarRef,
  };
};
