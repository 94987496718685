import type {
  FunctionComponent,
  MutableRefObject,
  ReactElement,
  SVGProps,
  SyntheticEvent,
} from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  OverflowMenu,
  OverflowMenuItem,
  TableCell,
  TableExpandRow,
  TableExpandedRow,
  TableRow,
  TableSelectRow,
} from '@carbon/react';
import { DateTime } from 'luxon';
import { EventSchedule, Information, Share } from '@carbon/icons-react';
import Tooltip from '../Tooltip';
import { REPORT_STATUS } from '../Workspace';
import EditReportName from '../EditReportName';
import ExpandedRowContent from './ExpandedRowContent';
import { StatusCell } from './StatusCell';
import type { JustUpdatedStatus } from './MyReportsTable';

interface RowProps {
  key: string;
  isExpanded: boolean;
  ariaLabel: string;
  isSelected: boolean;
  disabled: boolean;
  onExpand: () => void;
}

export interface ActionOption {
  dataTestid: (id: string) => string;
  itemText: string;
  hidden?: boolean;
  onClick: (row: CarbonSelectedRow, helper: (...args) => void) => void;
  isScheduled?: boolean;
  isDelete?: boolean;
  hasDivider?: boolean;
  icon?: (props: { size?: number } & SVGProps<SVGElement>) => ReactElement;
  iconClassName?: string;
}

export type RowStatus =
  | 'Deleting'
  | 'Cancelling'
  | 'Retrieving'
  | 'Adding to Favourites'
  | 'Removing from Favourites'
  | null;

interface TableRowProps {
  row: CarbonSelectedRow;
  isActive: boolean;
  rowStatus: RowStatus;
  isOverflowMenuDisabled: boolean;
  expandedRowLength?: number;
  rowProps: RowProps;
  selectionProps: SelectionProps;
  isEditable?: boolean;
  justUpdatedStatus?: JustUpdatedStatus;
  isAuthor: boolean;
  isFavourite: boolean;
  scrollbarRef?: MutableRefObject<HTMLDivElement>;
  hasId?: (rowId: string) => boolean;
  setActiveRow: (id: string) => void;
  handleRowClick: (row: TableRow) => void;
  expandRow: (id: string) => void;
  handleDelete?: (rows: CarbonSelectedRow[], callback?: () => void) => void;
  actionOptions: (options: {
    scheduledStatus?: string;
    isScheduleOptionDisabled: boolean;
    isAuthor: boolean;
    isFavourite: boolean;
  }) => ActionOption[];
  handleUpdateScheduleReport?: (row: CarbonSelectedRow) => void;
}

const ReportTableRow: FunctionComponent<TableRowProps> = ({
  row,
  isActive,
  rowStatus,
  isOverflowMenuDisabled,
  expandedRowLength,
  rowProps,
  selectionProps,
  isEditable,
  justUpdatedStatus,
  isAuthor,
  isFavourite,
  scrollbarRef,
  actionOptions,
  hasId,
  setActiveRow,
  handleRowClick,
  expandRow,
  handleDelete,
  handleUpdateScheduleReport,
}) => {
  const scheduledStatus = useMemo(() => {
    const cellValue =
      row.cells.find(({ info }) => info.header === 'schedule_data')?.value
        ?.status || '';
    return cellValue;
  }, [row.cells]);

  const isValidScheduled = useMemo(() => {
    const scheduledCell = row.cells.find(
      ({ info }) => info.header === 'schedule_data'
    );
    const cellValue = scheduledCell?.value;
    return (
      cellValue?.status === REPORT_STATUS.CANCELLED || cellValue?.has_children
    );
  }, [row.cells]);

  const RowComponent = isValidScheduled ? TableExpandRow : TableRow;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleStatusTooltip = (status: string) => {
    switch (status) {
      case 'Completed':
        return 'Requested report is ready to be reviewed';
      case 'Processing':
        return 'Requested report is being processed';
      case 'Failed':
        return "Requested report couldn't be generated";
      case 'Completed with no data':
        return 'Requested report failed due to lack of data';
      case 'Cancelled':
        return 'Requested report automatically cancelled';
      case 'Scheduled':
        return 'This report has been scheduled to execute';
    }
  };

  const getStatusText = () => {
    return rowStatus ? `${rowStatus}...` : '';
  };

  const status = row.cells.find((cell) => cell.info.header === 'status')?.value;

  const isScheduleOptionDisabled = useMemo(() => {
    return (
      status === REPORT_STATUS.FAILED ||
      status === REPORT_STATUS.CANCELLED ||
      status === REPORT_STATUS.NO_DATA
    );
  }, [status]);

  const renderScheduleCell = (value: ScheduleData) => {
    if (value?.status === REPORT_STATUS.SCHEDULED) {
      return (
        <>
          <span>
            {DateTime.fromISO(value.start_date).toFormat('d/MM/yyyy')}
          </span>
          <span>{value.recurrence_type}</span>
          <Tooltip description="Update Schedule" useButtonWrapper>
            <EventSchedule
              data-testid={`schedule-icon-${row.id}`}
              size={19}
              onClick={(e) => {
                e.stopPropagation();
                handleUpdateScheduleReport(row);
              }}
            />
          </Tooltip>
        </>
      );
    }

    if (value?.status === REPORT_STATUS.CANCELLED) {
      return <span>Cancelled Schedule</span>;
    }

    return null;
  };

  const getCellContent = (cell: CarbonCell<string | ScheduleData>) => {
    switch (cell.info.header) {
      case 'status': {
        return (
          <StatusCell
            row={row}
            cell={cell as CarbonCell<REPORT_STATUS>}
            handleStatusTooltip={handleStatusTooltip}
            hasId={hasId}
            getStatusText={getStatusText}
            justUpdatedStatus={justUpdatedStatus}
          />
        );
      }
      case 'days_before_delete': {
        const days = Number(cell.value);

        const pluralSuffix = days > 1 ? 's' : '';
        const remainingDays =
          days > 0 ? `${days} day${pluralSuffix}` : '< 1 day';

        if (days > 5) {
          return (
            <div className="ArchivedReportsTable__deletion">
              {remainingDays}
            </div>
          );
        }

        return (
          <div className="ArchivedReportsTable__deletion-with-icon">
            {remainingDays}
            <Tooltip
              label={`Permanent deletion in ${remainingDays}`}
              useButtonWrapper
            >
              <Information
                data-testid={`deletion-icon-${cell.id.split(':')[0]}`}
              />
            </Tooltip>
          </div>
        );
      }
      case 'schedule_data': {
        const { value } = cell;
        return (
          <div className="ReportTableRow__schedule-cell">
            {renderScheduleCell(value as ScheduleData)}
          </div>
        );
      }
      case 'shared_with_users': {
        const value = (cell.value as { [key: string]: string }) || {};
        const shareCount = Object.keys(value).length;
        const sharingText =
          shareCount > 1 ? `${shareCount} people` : '1 person';

        const shareAction = actions.menuActions.find(
          (action) => action.itemText === 'Share'
        );

        return (
          <div className="ReportTableRow__shared-cell">
            {sharingText}
            <Tooltip label="View Users & Share/Unshare" useButtonWrapper>
              <Share
                data-testid="report-share-icon"
                onClick={(e: SyntheticEvent) => {
                  e.stopPropagation();
                  shareAction.onClick(row, expandRow);
                }}
              />
            </Tooltip>
          </div>
        );
      }
      case 'report_name': {
        return (
          <div className="report-name-container">
            {isEditable && isAuthor ? (
              <EditReportName cell={cell as CarbonCell<string>} />
            ) : (
              (cell.value as string)
            )}
          </div>
        );
      }
      default:
        return cell.value;
    }
  };

  const actions = useMemo(() => {
    const actions = actionOptions({
      scheduledStatus,
      isScheduleOptionDisabled,
      isAuthor,
      isFavourite,
    }).filter(({ hidden }) => !hidden);
    const iconActions = actions.slice(0, 3);
    const menuActions = actions.slice(3);
    return { iconActions, menuActions };
  }, [
    scheduledStatus,
    isScheduleOptionDisabled,
    isAuthor,
    isFavourite,
    actionOptions,
  ]);

  const getOverflowMenuPosition = useCallback(
    (menuRef: HTMLElement, _: string, buttonRef: HTMLElement) => {
      const { height, width } = menuRef.getBoundingClientRect();
      const {
        height: buttonHeight,
        width: buttonWidth,
        bottom: buttonBottom,
      } = buttonRef.getBoundingClientRect();
      const { bottom: paginationBottom } =
        scrollbarRef.current.getBoundingClientRect();

      const left = -(width / 2 - buttonWidth / 2);
      return height + buttonBottom > paginationBottom
        ? { top: -(height + buttonHeight), left }
        : { top: 0, left };
    },
    []
  );

  useEffect(() => {
    const closeMenu = () => {
      setIsMenuOpen(false);
    };
    const appContainer = document.querySelector('#root > .app');
    appContainer.addEventListener('scroll', closeMenu);
    return () => {
      appContainer.removeEventListener('scroll', closeMenu);
    };
  }, []);

  return (
    <>
      <RowComponent
        data-testid={row.id}
        tabIndex={0}
        onMouseDown={() => setActiveRow(row.id)}
        onMouseUp={() => setActiveRow(null)}
        onKeyPress={(key) => {
          if (key.code === 'Enter') {
            setActiveRow(row.id);
          }
        }}
        className={isActive ? 'selected' : 'unselected'}
        {...rowProps}
      >
        {!isValidScheduled ? (
          <TableCell onClick={() => handleRowClick(row)} />
        ) : null}
        <TableSelectRow
          className="table-row-checkbox"
          key={row.id}
          {...selectionProps}
        />
        {row.cells.map((cell) => {
          return (
            <TableCell key={cell.id} onClick={() => handleRowClick(row)}>
              {getCellContent(cell)}
            </TableCell>
          );
        })}
        <TableCell>
          <div
            className={`actions-cell ${
              isOverflowMenuDisabled || rowStatus ? 'disabled' : ''
            }`}
          >
            {status !== REPORT_STATUS.PROCESSING && (
              <>
                {actions.iconActions.map(
                  ({
                    itemText,
                    dataTestid,
                    onClick,
                    icon: Icon,
                    iconClassName,
                  }) => (
                    <Tooltip
                      key={itemText}
                      description={itemText}
                      useButtonWrapper
                    >
                      <Icon
                        onClick={() => {
                          if (!isOverflowMenuDisabled && !rowStatus) {
                            onClick(row, expandRow);
                          }
                        }}
                        size={18}
                        className={iconClassName}
                        data-testid={dataTestid(row.id)}
                      />
                    </Tooltip>
                  )
                )}
                {actions.menuActions.length > 0 && (
                  <OverflowMenu
                    data-testid={`overflow-${row.id}`}
                    ariaLabel={`overflow-menu-${row.id}`}
                    disabled={isOverflowMenuDisabled || !!rowStatus}
                    size="lg"
                    menuOptionsClass="table-row-overflow-menu"
                    flipped
                    open={isMenuOpen}
                    onOpen={() => setIsMenuOpen(true)}
                    onClose={() => setIsMenuOpen(false)}
                    menuOffsetFlip={getOverflowMenuPosition}
                    focusTrap={false}
                  >
                    {actions.menuActions.map(
                      ({
                        itemText,
                        dataTestid,
                        onClick,
                        isScheduled,
                        icon: Icon,
                      }) => {
                        return (
                          (typeof isScheduled !== 'boolean' || isScheduled) && (
                            <OverflowMenuItem
                              key={itemText}
                              data-testid={dataTestid(row.id)}
                              itemText={
                                <>
                                  <Icon /> <span>{itemText}</span>
                                </>
                              }
                              onClick={() => onClick(row, expandRow)}
                            />
                          )
                        );
                      }
                    )}
                  </OverflowMenu>
                )}
              </>
            )}
          </div>
        </TableCell>
      </RowComponent>
      {isValidScheduled ? (
        <TableExpandedRow colSpan={expandedRowLength}>
          <ExpandedRowContent
            row={row}
            isDeleting={rowStatus === 'Deleting'}
            isAuthor={isAuthor}
            hasId={hasId}
            handleStatusTooltip={handleStatusTooltip}
            handleRowClick={handleRowClick}
            handleDelete={handleDelete}
            getStatusText={getStatusText}
          />
        </TableExpandedRow>
      ) : null}
    </>
  );
};

export default ReportTableRow;
