import type { FunctionComponent } from 'react';
import { useContext, useState } from 'react';
import { Share } from '@carbon/icons-react';
import Gravatar from '../Gravatar';
import SidePanelSection from '../SidePanel/SidePanelSection';
import SidePanelActions from '../SidePanel/SidePanelActions';
import { useAuth0 } from '@auth0/auth0-react';
import apiRequest from '../../api';
import { CACHE_KEY } from '../../constants/api';
import { AppContext } from '../../providers/AppProvider';
import AllUsers from './AllUsers';
import SharedUsers from './SharedUsers';
import { getReportLink, updateUserArray } from '../../utils/reportTableUtils';
import { ModalContext } from '../../providers/ModalProvider';
import { getSharedUserArray } from '../../utils';
import { SidePanelContext } from '../../providers/SidePanelProvider';
import {
  shareReportFailure,
  shareReportSuccess,
} from '../../constants/posthog';
import usePosthog from '../../utils/posthog';

interface ShareReportProps {
  bannerId: number;
  groupId: number;
  report: ReportMetaData;
}

export interface SharedUser {
  name: string;
  userId: string;
}

const ShareReport: FunctionComponent<ShareReportProps> = ({
  bannerId,
  groupId,
  report,
}) => {
  const { run_id, user_name } = report;
  const SHARED_WITH_CACHE_KEY = `${CACHE_KEY.REPORTS}-shared-${report.run_id}`;
  const { clearCacheForKey } = useContext(AppContext);
  const { getAccessTokenSilently } = useAuth0();
  const { updateModal } = useContext(ModalContext);
  const { toggleSidePanelExpand } = useContext(SidePanelContext);
  const posthogEvent = usePosthog();

  const [sharing, setSharing] = useState(false);
  const [sharedWith, setSharedWith] = useState<SharedUser[]>([]);
  const [addedUsers, setAddedUsers] = useState<SharedUser[]>([]);
  const [removedUsers, setRemovedUsers] = useState<SharedUser[]>([]);

  const updateSharedWithData = (sharedUserObject: {
    [key: string]: string;
  }) => {
    const sharedUserArray = getSharedUserArray(sharedUserObject);
    setSharedWith(sharedUserArray);
  };

  const handleUserClick = (user: SharedUser) => {
    const updatedUsers = updateUserArray(user, addedUsers);
    setAddedUsers(updatedUsers);
  };

  const handleSharedUserClick = (user: SharedUser) => {
    const updatedUsers = updateUserArray(user, removedUsers);
    setRemovedUsers(updatedUsers);
  };

  const handleShare = async () => {
    setSharing(true);
    const token = await getAccessTokenSilently();

    try {
      const requests = [];
      if (removedUsers.length > 0) {
        requests.push(
          await apiRequest(`/reports/${run_id}/unshare`, 'POST', token, {
            user_ids: removedUsers.map((u) => u.userId),
          })
        );
      }

      if (addedUsers.length > 0) {
        requests.push(
          await apiRequest(`/reports/${run_id}/share`, 'POST', token, {
            user_ids: addedUsers.map((u) => u.userId),
          })
        );
      }

      await Promise.all(requests);
      await apiRequest(
        `/reports/${bannerId}/user-groups/${groupId}/data?run_id=${run_id}`,
        'GET',
        token
      );
      clearCacheForKey(SHARED_WITH_CACHE_KEY);
      clearCacheForKey(CACHE_KEY.REPORTS);
      setAddedUsers([]);
      setRemovedUsers([]);
      posthogEvent(shareReportSuccess, {
        userIds: addedUsers.map((u) => u.userId),
      });
      updateModal({
        type: 'success',
        title: 'Sharing status updated',
        body: 'This report has had its sharing information updated successfully',
        copyToClipboard:
          addedUsers.length > 0
            ? window.location.origin + getReportLink(run_id, bannerId, groupId)
            : undefined,
      });
    } catch {
      posthogEvent(shareReportFailure);
      updateModal({
        type: 'error',
        title: 'Report sharing failed',
        body: 'An error occurred when attempting to share this report. Please try again.',
      });
    } finally {
      toggleSidePanelExpand(false);
      setSharing(false);
    }
  };

  return (
    <>
      <SidePanelSection sectionHeader="Report Author">
        <Gravatar name={user_name} />
      </SidePanelSection>

      <SidePanelSection
        sectionHeader="Shared With"
        sectionDescription="Select users to unshare this report"
      >
        <SharedUsers
          bannerId={bannerId}
          groupId={groupId}
          run_id={run_id}
          sharedWith={sharedWith}
          removedUsers={removedUsers}
          handleSharedUserClick={handleSharedUserClick}
          updateSharedWithData={updateSharedWithData}
        />
      </SidePanelSection>

      <SidePanelSection
        sectionHeader="Available team members"
        sectionDescription="Select users to share this report with"
        showDivider={addedUsers.length + removedUsers.length > 0}
      >
        <AllUsers
          apiUrl={`/user-groups/${groupId}/users`}
          checkedUsers={addedUsers}
          filterUsers={(user: SharedUser) =>
            !sharedWith.map((sw) => sw.userId).includes(user.userId)
          }
          handleUserClick={handleUserClick}
        />
      </SidePanelSection>
      {addedUsers.length + removedUsers.length > 0 && (
        <SidePanelSection
          sectionHeader="Pending changes"
          sectionDescription="Changes to be made"
          showDivider={false}
        >
          {addedUsers.length > 0 && (
            <div>
              <div className="description">Added team members</div>
              <div className="flex">
                {addedUsers.map((user) => {
                  return (
                    <Gravatar
                      className="clickable hoverable"
                      key={user.userId}
                      showName={false}
                      name={user.name}
                      onClick={() => handleUserClick(user)}
                    />
                  );
                })}
              </div>
            </div>
          )}
          {removedUsers.length > 0 && (
            <div>
              <div className="description">Removed team members</div>
              <div className="flex">
                {removedUsers.map((user) => {
                  return (
                    <Gravatar
                      className="clickable hoverable"
                      key={user.userId}
                      showName={false}
                      name={user.name}
                      onClick={() => handleSharedUserClick(user)}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </SidePanelSection>
      )}

      <SidePanelActions
        actions={[
          {
            name: 'Save',
            disabled: sharing || addedUsers.length + removedUsers.length === 0,
            loading: sharing,
            icon: Share,
            onClick: handleShare,
          },
        ]}
      />
    </>
  );
};
export default ShareReport;
