import type { ReactNode } from 'react';
import { useRef } from 'react';
import { Accordion, AccordionItem, Button, Checkbox } from '@carbon/react';
import { Filter, FilterRemove } from '@carbon/icons-react';
import ConditionalWrapper from './ConditionalWrapper';

export interface FilterConfig {
  filterKey: string;
  title: ReactNode;
  isHidden?: boolean;
  options: { value: string | number; label?: ReactNode; id?: string }[];
}

interface FiltersContentProps<T> {
  readonly filterState: T;
  readonly filterConfig: FilterConfig[];
  readonly isClearButtonVisible?: boolean;
  readonly conditionalWrapper?: (children: ReactNode) => ReactNode;
  readonly changeFilterValue: (
    option: unknown,
    checked: boolean,
    value: string | number
  ) => void;
  readonly clearAllFilters?: () => void;
}

const FiltersContent = <T,>({
  filterState,
  isClearButtonVisible,
  filterConfig,
  conditionalWrapper,
  changeFilterValue,
  clearAllFilters,
}: FiltersContentProps<T>) => {
  const popoverContentRef = useRef<HTMLDivElement>(null);
  const isChecked = (option: string, currentValue: string | number) => {
    return (
      filterState[option].find((value) => value === currentValue) !== undefined
    );
  };

  return (
    <div className="FiltersContent__container" ref={popoverContentRef}>
      <div className="FiltersContent__header">
        <div className="FiltersContent__title">
          Filters <Filter size={18} />
        </div>
        {isClearButtonVisible && clearAllFilters && (
          <Button
            kind="ghost"
            renderIcon={FilterRemove}
            onClick={clearAllFilters}
            data-testid="clear-all-filters"
            size="sm"
          >
            Clear all
          </Button>
        )}
      </div>
      <ConditionalWrapper
        wrapper={conditionalWrapper}
        condition={!!conditionalWrapper}
      >
        <Accordion
          size="md"
          className="FiltersContent__filters-container"
          id="filter-accordion-list"
        >
          {filterConfig.map(
            ({ title, filterKey, isHidden, options }) =>
              !isHidden && (
                <AccordionItem
                  key={filterKey}
                  title={
                    <div className="FiltersContent__filter-title">{title}</div>
                  }
                  open
                  className="FiltersContent__filter-heading"
                >
                  <div className="FiltersContent__options-container">
                    {options.map(({ value, label, id }) => (
                      <Checkbox
                        key={`checkbox-${id ?? value}`}
                        labelText={label ?? value}
                        checked={isChecked(filterKey, value)}
                        data-testid={`filter-checkbox-${value}`}
                        className="FiltersContent__filters-checkbox"
                        id={`checkbox-${value}`}
                        onChange={(_, { checked }) =>
                          changeFilterValue(filterKey, checked, value)
                        }
                      />
                    ))}
                  </div>
                </AccordionItem>
              )
          )}
        </Accordion>
      </ConditionalWrapper>
    </div>
  );
};

export default FiltersContent;
