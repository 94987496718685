import type { FunctionComponent } from 'react';
import { useState } from 'react';
import {
  Button,
  ProgressIndicator,
  ProgressStep,
  InlineLoading,
} from '@carbon/react';
import { ChevronRight } from '@carbon/icons-react';
import FormStep from './FormStep';
import '../../styles/components/formStepper.scss';

interface FormStepType {
  title: string;
  label: string;
  component: JSX.Element;
  icon: JSX.Element;
  nextDisabled?: boolean;
  isSubmitStep?: boolean;
  isValid?: boolean;
}

interface FormStepperProps {
  steps: FormStepType[];
  isFormValid?: boolean;
  submitText?: string;
  submitting?: boolean;
  onSubmit: () => void;
}

const FormStepper: FunctionComponent<FormStepperProps> = ({
  steps,
  isFormValid = true,
  submitText,
  submitting,
  onSubmit,
}) => {
  const [activeStep, setActiveStep] = useState(0);

  const { title, component, icon } = steps[activeStep];

  return (
    <div className="FormStepper">
      <ProgressIndicator
        spaceEqually
        currentIndex={activeStep}
        onChange={(index) => setActiveStep(index)}
      >
        {steps.map((step, index) => (
          <ProgressStep
            key={step.label}
            label={step.label}
            complete={activeStep > index}
            current={activeStep === index}
            invalid={!steps[index].isValid}
            disabled={index > activeStep || submitting}
          />
        ))}
      </ProgressIndicator>

      <FormStep title={title} icon={icon}>
        {component}
      </FormStep>

      <div className="FormStepper__action-container">
        <Button
          onClick={() => setActiveStep(Math.max(activeStep - 1, 0))}
          kind="secondary"
          size="sm"
          disabled={activeStep === 0 || submitting}
        >
          Back
        </Button>
        {steps[activeStep].isSubmitStep ? (
          <Button
            className="has-icon"
            renderIcon={submitting ? InlineLoading : ChevronRight}
            size="sm"
            disabled={
              steps[activeStep].nextDisabled ||
              submitting ||
              !steps[activeStep].isValid ||
              (steps[activeStep].isSubmitStep && !isFormValid)
            }
            onClick={onSubmit}
          >
            {submitText || 'Submit'}
          </Button>
        ) : (
          <Button
            className="has-icon"
            renderIcon={ChevronRight}
            size="sm"
            disabled={
              steps[activeStep].nextDisabled || !steps[activeStep].isValid
            }
            onClick={() =>
              setActiveStep(Math.min(activeStep + 1, steps.length - 1))
            }
          >
            Next
          </Button>
        )}
      </div>
    </div>
  );
};

export default FormStepper;
