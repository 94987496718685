import type { CSSProperties, FunctionComponent } from 'react';
import { Tooltip as CarbonTooltip } from '@carbon/react';
interface TooltipProps {
  align?:
    | 'top'
    | 'top-left'
    | 'top-right'
    | 'bottom'
    | 'bottom-left'
    | 'bottom-right'
    | 'left'
    | 'left-bottom'
    | 'left-top'
    | 'right'
    | 'right-bottom'
    | 'right-top';
  defaultOpen?: boolean;
  description?: string;
  enterDelayMs?: number;
  label?: string;
  leaveDelayMs?: number;
  style?: CSSProperties;
  useButtonWrapper?: boolean;
  className?: string;
  children: JSX.Element;
}

const Tooltip: FunctionComponent<TooltipProps> = ({
  align,
  defaultOpen,
  description,
  enterDelayMs,
  label,
  leaveDelayMs,
  style,
  useButtonWrapper,
  className,
  children,
}) => {
  return (
    <CarbonTooltip
      align={align}
      defaultOpen={defaultOpen}
      description={description}
      enterDelayMs={enterDelayMs}
      label={label}
      leaveDelayMs={leaveDelayMs}
      className={className}
    >
      {useButtonWrapper ? (
        <button style={{ all: 'unset', ...style }}>{children}</button>
      ) : (
        <a style={{ all: 'unset', ...style }}>{children}</a>
      )}
    </CarbonTooltip>
  );
};

export default Tooltip;
