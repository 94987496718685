import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { Button, InlineNotification, Search, RadioButton } from '@carbon/react';
import Fetch from '../Fetch';
import type { SharedUser } from './ShareReport';
import Gravatar from '../Gravatar';
import type { UserData } from '../../reducers/AdminPanelReducer';

type UserType = SharedUser | UserData;

interface AllUsersProps {
  readonly apiUrl: string;
  readonly checkedUsers: UserType[];
  readonly selectionButtons?: { primary: string; secondary: string };
  readonly useRadioButton?: boolean;
  readonly filterUsers: (user: UserType) => boolean;
  readonly handleUserClick: (user: UserType) => void;
}
const AllUsers: FunctionComponent<AllUsersProps> = ({
  checkedUsers,
  selectionButtons = { primary: 'Remove', secondary: 'Add' },
  apiUrl,
  useRadioButton,
  filterUsers,
  handleUserClick,
}) => {
  const [allUsers, setAllUsers] = useState<UserType[]>([]);
  const [search, setSearch] = useState('');

  const filteredUsers = allUsers.filter(filterUsers).filter((user) => {
    return user.name.toLowerCase().match(search.toLowerCase());
  });

  return (
    <Fetch
      apiUrl={apiUrl}
      initialData={null}
      alwaysFetchOnMount
      loadingMessage="Loading users..."
      hideChildrenUntilFetched
      onReceiveData={(data) => {
        if (data) {
          setAllUsers(data);
        }
      }}
    >
      <Search
        data-testid="user-search"
        className="user-search"
        size="sm"
        value={search}
        labelText="Search"
        placeholder="Search for user"
        closeButtonLabelText="Clear search"
        onChange={({ target }) => {
          setSearch(target.value);
        }}
        onClear={() => setSearch('')}
      />

      {filteredUsers.length === 0 ? (
        <InlineNotification
          kind="info"
          lowContrast
          title="No users available to share with."
          hideCloseButton
        />
      ) : (
        filteredUsers.map((user) => {
          const userId = (user as SharedUser)?.userId ?? (user as UserData)?.id;
          return (
            <div
              className={`flex ${
                !useRadioButton ? 'justify-space-between' : ''
              }`}
              style={{ margin: '4px 0' }}
              key={userId}
            >
              {useRadioButton ? (
                <RadioButton
                  checked={checkedUsers.includes(user)}
                  labelText={
                    <Gravatar
                      name={user.name}
                      checked={checkedUsers.includes(user)}
                    />
                  }
                  className="AllUsers__radio-button"
                  onClick={() => handleUserClick(user)}
                />
              ) : (
                <>
                  <Gravatar
                    name={user.name}
                    checked={checkedUsers.includes(user)}
                  />
                  <Button
                    kind="tertiary"
                    size="sm"
                    onClick={() => handleUserClick(user)}
                  >
                    {checkedUsers.includes(user)
                      ? selectionButtons.primary
                      : selectionButtons.secondary}
                  </Button>
                </>
              )}
            </div>
          );
        })
      )}
    </Fetch>
  );
};

export default AllUsers;
