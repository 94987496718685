import { Outlet, useNavigate } from 'react-router-dom';
import { SettingsAdjust } from '@carbon/icons-react';
import type { FunctionComponent } from 'react';
import { useContext, useEffect } from 'react';
import IconTitle from '../IconTitle';
import { AppContext } from '../../providers/AppProvider';
import { CACHE_KEY } from '../../constants/api';
import { CustomGroupsContext } from '../../providers/CustomGroupsProvider';

const CustomGroupsLayout: FunctionComponent = () => {
  const navigate = useNavigate();
  const { bannerId, groupId, clearCacheForKey, updateDimensionsData } =
    useContext(AppContext);
  const { selectedDimGroup } = useContext(CustomGroupsContext);

  const hierarchiesToClear = [
    CACHE_KEY.DIMENSIONS,
    CACHE_KEY.CUSTOM_PRODUCTS,
    CACHE_KEY.CUSTOM_LOCATION,
  ];

  useEffect(() => {
    return () => {
      hierarchiesToClear.forEach((key) => {
        clearCacheForKey(key);
      });
      updateDimensionsData([]);
    };
  }, []);

  useEffect(() => {
    selectedDimGroup && navigate('');
  }, [bannerId, groupId]);

  return (
    <>
      <IconTitle
        icon={<SettingsAdjust size={20} />}
        title="Custom Groups"
        kind="primary"
      />
      <Outlet />
    </>
  );
};

export default CustomGroupsLayout;
