import * as d3 from 'd3';

export const clearSVG = () => {
  d3.selectAll('#dendrogram > svg').remove();
};

export const getNodes = (
  data: DendrogramNode,
  height: number,
  width: number
) => {
  const cluster = d3
    .cluster()
    .size([height, width])
    .separation(() => 1);

  const root = d3.hierarchy(data, (d) => d.children);

  cluster(root);

  return root.descendants().slice(1);
};
