import type { FunctionComponent } from 'react';
import { useContext, useEffect, useState } from 'react';
import { Button, FormLabel } from '@carbon/react';
import type { ReportConfig } from '../../reducers/ReportReducer';
import ParameterSummary from '../ReportBuilder/ParameterSummary';
import { AppContext } from '../../providers/AppProvider';

interface ReportMetadataProps {
  reportConfig: ReportConfig;
  reportTemplateIndex: number;
  handleEdit: () => void;
  handleCancel: () => void;
  handleDuplicate: () => void;
}

const ReportMetaData: FunctionComponent<ReportMetadataProps> = ({
  reportConfig,
  reportTemplateIndex,
  handleEdit,
  handleCancel,
  handleDuplicate,
}) => {
  const [reportParams, setReportParams] = useState<ReportParameters>(null);
  const { user } = useContext(AppContext);

  useEffect(() => {
    reportConfig &&
      setReportParams(
        reportConfig.parameters.template_requests[reportTemplateIndex]
      );
  }, [reportConfig]);

  const overlappingParams = {};

  if (reportParams?.overlap?.length) {
    const overlapGroups = reportParams.overlap as string[];
    overlapGroups.forEach((item) => {
      overlappingParams[item] = {
        hasOverlap: true,
        reason: 'This dimension has an overlapping selection.',
      };
    });
  }

  return (
    <>
      {reportParams && (
        <div className="ReportMetaData">
          <div className="ReportMetaData__title">Report summary</div>
          <div className="ReportMetaData__content">
            <div className="SidePanelTitle__section-title">Report details</div>
            <div className="ReportMetaData__summary-section">
              <div className="ReportMetaData__report-name">
                <FormLabel>{reportConfig.report_name}</FormLabel>
              </div>
            </div>
            <div className="SidePanelTitle__section-title">Report details</div>
            <div className="ReportMetaData__user-name">
              <span className="ReportMetaData__user-name">Owner: </span>
              <FormLabel>{reportConfig.user_name}</FormLabel>
            </div>
            <div className="ReportMetaData__summary-section">
              <ParameterSummary
                isEditable
                isTitleHidden
                parameters={reportParams}
                overlap={overlappingParams}
              />
            </div>
          </div>
          <div className="ReportMetaData__actions">
            <Button
              kind="tertiary"
              onClick={handleCancel}
              data-testid="handleCancel-btn"
            >
              Cancel
            </Button>
            <Button
              kind={reportConfig.user_id === user.id ? 'secondary' : 'primary'}
              onClick={handleDuplicate}
              data-testid="handleDuplicate-btn"
            >
              Duplicate
            </Button>
            {reportConfig.user_id === user.id && (
              <Button onClick={handleEdit} data-testid="handleEdit-btn">
                Edit
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ReportMetaData;
