import type { FunctionComponent, ReactElement } from 'react';
import { useContext, useEffect } from 'react';
import { Tabs, TabList, Tab } from '@carbon/react';
import {
  Outlet,
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { ReportFilterContext } from '../providers/ReportFilterProvider';
import { getFilterParam } from '../utils/filterUtils';
import { AppContext } from '../providers/AppProvider';
import usePosthog from '../utils/posthog';
import { workspacePageTabClick } from '../constants/posthog';

interface TabsContainerProps {
  tabs: {
    label: string;
    icon: () => ReactElement;
    path: string;
  }[];
  activeTab: number;
}

const TabsContainer: FunctionComponent<TabsContainerProps> = ({
  activeTab,
  tabs,
}) => {
  const { tabs: tabsState, resetFilters } = useContext(ReportFilterContext);
  const { groupId, bannerId } = useContext(AppContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const posthogEvent = usePosthog();
  const [searchParameters] = useSearchParams();

  const handleTabClick = (path: string) => {
    if (pathname !== `/workspace/${path}`) {
      const activeTabState = tabsState[path];
      posthogEvent(workspacePageTabClick, {
        tab: path,
        origin: 'workspace_page',
      });
      const searchParams = Object.entries(activeTabState).reduce(
        (acc, item) => {
          const [option, value] = item;
          const pageSize = searchParameters.get('page_size')
            ? { page_size: searchParameters.get('page_size') }
            : {};
          if (value.length > 0) {
            const paramValue = getFilterParam(option, value);
            acc[option] = paramValue;
          }
          return {
            ...acc,
            ...pageSize,
          };
        },
        {}
      );
      navigate({
        pathname: `/workspace/${path}`,
        search: createSearchParams(searchParams)?.toString(),
      });
    }
  };

  useEffect(() => {
    resetFilters();
  }, [groupId, bannerId]);

  return (
    <>
      {activeTab !== -1 && (
        <Tabs selectedIndex={activeTab}>
          <TabList
            aria-label="List of report tables"
            className="tabs-list-container"
          >
            {tabs.map(({ label, icon: Icon, path }) => (
              <Tab
                className="workspace-tab hoverable"
                key={label}
                onClick={() => handleTabClick(path)}
              >
                <div>
                  <Icon />
                  <span>{label}</span>
                </div>
              </Tab>
            ))}
          </TabList>
        </Tabs>
      )}
      <div className="tab-panel-container">
        <Outlet />
      </div>
    </>
  );
};

export default TabsContainer;
