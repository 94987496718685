/* eslint-disable indent */
import ChartProvider from '../providers/ChartProvider';
import type { ReportConfig, VisualData } from '../reducers/ReportReducer';
import { VisualType } from '../reducers/ReportReducer';
import { Grid, Column } from '@carbon/react';
import { DataError } from '@carbon/icons-react';
import { applyYAxisFormat, getPlotOptions, getXAxis } from '.';
import Chart from '../components/Charts/Chart';
import ChartContainer from '../components/Charts/ChartContainer';
import type { CustomAction } from '../components/Charts/DataGrid';
import DataGrid from '../components/Charts/DataGrid';
import Dendrogram from '../components/Charts/Dendrogram';
import ScatterWithSummary from '../components/Charts/ScatterWithSummary';
import TreeChart from '../components/Charts/TreeChart';
import Venn from '../components/Charts/Venn';
import {
  stackedColumnTooltip,
  columnChartTooltip,
  legendFormatter,
  customChartTooltip,
} from '../components/Report/TooltipFormatters';
import AssortmentGrid from '../components/Charts/AssortmentGrid';
import AssortmentDendrogram from '../components/Charts/AssortmentDendrogram';
import OptimiserGrid from '../components/Charts/OptimiserGrid';
import NoDataPlaceholder from '../components/ReportTable/NoDataPlaceholder';
import { checkGetVisualData } from './reportUtils';
import ProductSummary from '../components/Optimise/ProductSummary';
import type { SummaryCard } from '../components/Optimise/SummaryCards';
import SummaryCards from '../components/Optimise/SummaryCards';
import ReportMetricsCard from '../components/Cards/ReportMetricsCard';
import ReportCard from '../components/Cards/ReportCard';
import { OptimiserMode } from '../reducers/OptimiserFormReducer';

interface VisualOptions {
  measure?: Measure;
  reportConfig?: ReportConfig;
  isAuthor?: boolean;
  isSupplier?: boolean;
  isSkuSelected?: boolean;
  isSkuGridReady?: boolean;
  reportTemplateId: string;
  reportTemplateIndex: number;
  visualDropdownSelection?: string;
  visualHeader?: VisualHeader;
  gridCustomToolbarActions?: CustomAction[];
  gridCustomTableActions?: CustomAction[];
  tab: number | string;
  showExport?: boolean;
  exportAllCharts?: boolean;
  isVisible?: boolean;
  hasChildGrid?: boolean;
  isSecondaryAxisVisible?: boolean;
  toggleSkuGrid?: () => void;
  patchVisualsData?: (id: number | string, data: Partial<VisualData>) => void;
  handleGridServerExport?: (name: string) => Promise<void>;
}
export const getVisual = (
  id: number,
  type: VisualType,
  data: VisualData | { [key: string]: DendrogramNode },
  dropdownKey: string,
  options?: VisualOptions
) => {
  const {
    measure,
    reportConfig,
    isAuthor,
    isSupplier,
    isSkuSelected,
    isSkuGridReady,
    reportTemplateId,
    reportTemplateIndex,
    visualDropdownSelection,
    visualHeader,
    gridCustomToolbarActions,
    gridCustomTableActions,
    tab,
    showExport,
    exportAllCharts,
    isVisible,
    hasChildGrid,
    isSecondaryAxisVisible,
    toggleSkuGrid,
    patchVisualsData,
    handleGridServerExport,
  } = options || {};
  const visualData = data as VisualData;
  const isReportSplit = reportConfig?.report_version === 'v2';

  if (!checkGetVisualData(visualData, dropdownKey, type)) {
    return (
      <div className="getVisual__no-data-wrapper">
        <NoDataPlaceholder
          title="No Data"
          icon={DataError}
          description={{
            info: (
              <div className="getVisual__no-data-info">
                The selected input combination does not have any associated
                data.
                <br />
                Try altering your selection to obtain updated results.
              </div>
            ),
          }}
        />
      </div>
    );
  }

  switch (type) {
    case VisualType.SUMMARY_GRID:
    case VisualType.CELL_GRID:
      return (
        <div
          data-testid="data-grid"
          key={`${id}-${visualDropdownSelection}-${dropdownKey}`}
        >
          <DataGrid
            headerData={visualData.headers[dropdownKey]}
            rowData={visualData.rows[dropdownKey]}
            stickyColumns={visualData.stickyColumns}
            visibleColumns={visualData.isVisible}
            searchable
            searchableColumns={visualData.isSearchable}
            exportedFileName={reportConfig.report_name}
            visualData={visualData}
            columnWidths={visualData.columnWidths}
            id={id}
            resizable
            reportConfig={reportConfig}
            dropdownKey={dropdownKey}
            isAuthor={isAuthor}
            isSkuSelected={isSkuSelected}
            isSkuGridReady={isSkuGridReady}
            patchVisualsData={patchVisualsData}
            toggleSkuGrid={toggleSkuGrid}
            reportTemplateId={reportTemplateId}
            reportTemplateIndex={reportTemplateIndex}
            visualHeader={visualHeader}
            customToolbarActions={gridCustomToolbarActions}
            customTableActions={gridCustomTableActions}
            handleGridServerExport={handleGridServerExport}
            showPagination={type !== VisualType.SUMMARY_GRID}
            hasChildGrid={hasChildGrid}
          />
        </div>
      );

    case VisualType.OPTIMISER_GRID:
    case VisualType.OPTIMISER_PLAN_GRID:
      return (
        <div data-testid="optimiser-grid" className="hide-in-export">
          <OptimiserGrid
            isAuthor={isAuthor}
            isSupplier={isSupplier}
            dropdownKey={dropdownKey}
            reportTemplateId={reportTemplateId}
            reportTemplateIndex={reportTemplateIndex}
            mode={
              type === VisualType.OPTIMISER_GRID
                ? OptimiserMode.NonPlan
                : OptimiserMode.Plan
            }
          />
        </div>
      );

    case VisualType.DENDRO_GRID:
      return (
        <div data-testid="dendro-sticky-grid">
          <DataGrid
            headerData={visualData.headers[dropdownKey]}
            rowData={visualData.rows[dropdownKey]}
            stickyColumns={visualData.stickyColumns}
            visibleColumns={visualData.isVisible}
            collapsed
            showExport={false}
            isSortable={false}
            showPagination={false}
            exportedFileName={reportConfig.report_name}
            columnWidths={visualData.columnWidths}
            visualData={visualData}
            id={1}
            resizable
            isAuthor={isAuthor}
            reportConfig={reportConfig}
            reportTemplateIndex={reportTemplateIndex}
            reportTemplateId={reportTemplateId}
            patchVisualsData={patchVisualsData}
          />
        </div>
      );

    case VisualType.CDT_GRID:
      return (
        <div data-testid="assortment-grid">
          <AssortmentGrid
            dropdownKey={dropdownKey}
            reportTemplateId={reportTemplateId}
            reportTemplateIndex={reportTemplateIndex}
          />
        </div>
      );

    case VisualType.DENDROGRAM:
    case VisualType.CDT_DENDROGRAM: {
      const dendroData = data[0] as DendrogramNode;
      const numSkus = dendroData?.numSkus;
      return (
        <div data-testid="dendrogram" className="dendrogram-container">
          <ChartProvider>
            <ChartContainer
              id={id}
              visualType={type}
              showExpand={false}
              showToggle={false}
              useServerExportService
              visualHeader={visualHeader}
              tab={tab}
            >
              {type === VisualType.DENDROGRAM ? (
                <Dendrogram data={dendroData} numSkus={numSkus} />
              ) : (
                <AssortmentDendrogram data={dendroData} numSkus={numSkus} />
              )}
            </ChartContainer>
          </ChartProvider>
        </div>
      );
    }

    case VisualType.COLUMN:
      return (
        <ChartProvider>
          <ChartContainer
            id={id}
            visualType={type}
            activeMeasure={measure}
            visualHeader={visualHeader}
            tab={tab}
            isSecondaryAxisVisible={isSecondaryAxisVisible}
          >
            <Chart
              data-testid="column-chart"
              key={`${dropdownKey}_${visualDropdownSelection}`}
              id={`chart-${id}`}
              reportTemplateId={reportTemplateId}
              isVisible={isVisible}
              options={{
                series: visualData.seriesData[
                  dropdownKey
                ] as Highcharts.SeriesOptionsType[],
                chart: visualData.chart,
                plotOptions: getPlotOptions(visualData),
                xAxis: getXAxis(
                  visualData,
                  dropdownKey,
                  visualDropdownSelection
                ),
                yAxis: applyYAxisFormat(
                  visualData,
                  measure,
                  dropdownKey,
                  isReportSplit
                ),
                tooltip: {
                  formatter: function () {
                    return columnChartTooltip(measure, this, this.x);
                  },
                  useHTML: true,
                  className: 'chart-tooltip',
                  backgroundColor: null,
                  borderWidth: 0,
                  shadow: false,
                },
                legend: {
                  ...visualData.legend,
                  labelFormatter: function () {
                    return legendFormatter(this.name);
                  },
                  useHTML: true,
                },
              }}
            />
          </ChartContainer>
        </ChartProvider>
      );

    case VisualType.STACKED_COLUMN:
      return (
        <ChartProvider>
          <ChartContainer
            id={id}
            visualType={type}
            activeMeasure={measure}
            showExport={showExport}
            visualHeader={visualHeader}
            tab={tab}
            isSecondaryAxisVisible={isSecondaryAxisVisible}
          >
            <Chart
              key={`${dropdownKey}_${visualDropdownSelection}`}
              id={`chart-${id}`}
              reportTemplateId={reportTemplateId}
              isVisible={isVisible}
              options={{
                series: visualData.seriesData[
                  dropdownKey
                ] as Highcharts.SeriesOptionsType[],
                xAxis: getXAxis(
                  visualData,
                  dropdownKey,
                  visualDropdownSelection
                ),
                chart: visualData.chart,
                yAxis: applyYAxisFormat(
                  visualData,
                  measure,
                  dropdownKey,
                  isReportSplit
                ),
                plotOptions: getPlotOptions(visualData),
                tooltip: {
                  formatter: function () {
                    return stackedColumnTooltip(measure, this.points, this.x);
                  },
                  shared: true,
                  useHTML: true,
                  className: 'chart-tooltip',
                  backgroundColor: null,
                  borderWidth: 0,
                  shadow: false,
                  distance: 20,
                },
                legend: {
                  ...visualData.legend,
                  labelFormatter: function () {
                    return legendFormatter(this.name);
                  },
                  useHTML: true,
                },
              }}
            />
          </ChartContainer>
        </ChartProvider>
      );

    case VisualType.KPI_TREE:
      return (
        <div data-testid="tree-old">
          <TreeChart
            key={`${dropdownKey}_${visualDropdownSelection}`}
            treeId={id}
            treeData={visualData.seriesData[dropdownKey] as TreeChartData[]}
            layout={visualData.treeLayout}
            treeType="old"
            tab={tab}
            exportAllCharts={exportAllCharts}
            visualHeader={visualHeader}
          />
        </div>
      );

    case VisualType.TREE:
      return (
        <div data-testid="tree">
          <TreeChart
            key={`${dropdownKey}_${visualDropdownSelection}`}
            treeId={id}
            treeData={visualData.seriesData[dropdownKey] as TreeChartData[]}
            layout={visualData.treeLayout}
            visualHeader={visualHeader}
            treeType="new"
            tab={tab}
            exportAllCharts={exportAllCharts}
          />
        </div>
      );

    case VisualType.VENN:
      return (
        <ChartProvider>
          <ChartContainer
            id={id}
            visualType={type}
            activeMeasure={measure}
            showToggle={false}
            visualHeader={visualHeader}
            tab={tab}
          >
            <Venn
              vennData={visualData.vennData[dropdownKey]}
              vennLegend={visualData.vennLegendItems}
              vennSections={visualData.vennSections}
            />
          </ChartContainer>
        </ChartProvider>
      );

    case VisualType.SCATTER:
      return (
        <div data-testid="scatter">
          <ChartProvider>
            <ChartContainer
              id={id}
              visualType={type}
              showToggle={false}
              visualHeader={visualHeader}
              tab={tab}
              isSecondaryAxisVisible={isSecondaryAxisVisible}
            >
              <ScatterWithSummary
                visualData={visualData}
                reportTemplateId={reportTemplateId}
              />
            </ChartContainer>
          </ChartProvider>
        </div>
      );

    case VisualType.CUSTOM:
      return (
        <div data-testid="custom-visual">
          <ChartProvider>
            <ChartContainer
              id={id}
              visualType={type}
              activeMeasure={measure}
              visualData={visualData}
              dropdownKey={dropdownKey}
              visualHeader={visualHeader}
              tab={tab}
              isSecondaryAxisVisible={isSecondaryAxisVisible}
            >
              <Chart
                key={`${dropdownKey}_${visualDropdownSelection}`}
                id={`chart-${id}`}
                reportTemplateId={reportTemplateId}
                isVisible={isVisible}
                options={{
                  series: visualData.seriesData[
                    dropdownKey
                  ] as Highcharts.SeriesOptionsType[],
                  plotOptions: getPlotOptions(visualData),
                  chart: visualData.chart,
                  xAxis: getXAxis(
                    visualData,
                    dropdownKey,
                    visualDropdownSelection
                  ),
                  yAxis: visualData.measureDropdownId
                    ? applyYAxisFormat(
                        visualData,
                        measure,
                        dropdownKey,
                        isReportSplit
                      )
                    : visualData.yAxis,
                  tooltip: {
                    ...visualData.tooltip,
                    formatter: function () {
                      return customChartTooltip({
                        tooltipType: visualData.tooltipType,
                        measure,
                        context: this,
                      });
                    },
                    useHTML: true,
                    className: 'chart-tooltip',
                    backgroundColor: null,
                    borderWidth: 0,
                    shadow: false,
                  },
                  legend: {
                    ...visualData.legend,
                    labelFormatter: function () {
                      return legendFormatter(this.name);
                    },
                    useHTML: true,
                  },
                }}
              />
            </ChartContainer>
          </ChartProvider>
        </div>
      );

    case VisualType.CARDS:
      return reportConfig.report_type === 'Delist Transfer Analysis' ? (
        <Grid data-testid="card-grid">
          {(visualData.cards[dropdownKey] as Card[]).map((card) => {
            return (
              <Column key={card.title} lg={4} md={4} sm={4}>
                <ReportCard {...card} />
              </Column>
            );
          })}
        </Grid>
      ) : (
        <ReportMetricsCard
          items={visualData.cards[dropdownKey] as Card[]}
          visualHeader={visualHeader}
        />
      );

    case VisualType.PRODUCT_SUMMARY:
      return <ProductSummary summary={visualData.summary[dropdownKey][0]} />;

    case VisualType.SUMMARY_CARDS:
      return (
        <SummaryCards cards={visualData.cards[dropdownKey] as SummaryCard[]} />
      );
  }
};
