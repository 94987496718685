/* eslint-disable indent */

import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableExpandHeader,
  TableExpandRow,
  TableExpandedRow,
  Toggle,
  NumberInput,
} from '@carbon/react';
import Tooltip from '../../components/Tooltip';
import { Edit, Save } from '@carbon/icons-react';
import type { FunctionComponent, ReactNode } from 'react';
import { useContext, Fragment, useState } from 'react';
import { OptimiserFormContext } from '../../providers/OptimiserFormProvider';
import type {
  PlanTargetRow,
  LocationPlanTargetRow,
} from '../../reducers/OptimiserFormReducer';
import PlanogramTable from './PlanogramTable';
import { omit } from '../../utils/object';

const isValidTarget = (value: string | number | undefined): boolean => {
  if (typeof value === 'undefined' && value !== '') {
    return false;
  }

  const numericValue = Number(value);

  return (
    Number.isInteger(numericValue) && numericValue > 0 && numericValue < 100000
  );
};

const PlanTargets: FunctionComponent = () => {
  const {
    formData: { planTargets },
    updatePlanInclusion,
    updatePlanPlanogram,
    updatePlanTarget,
  } = useContext(OptimiserFormContext);

  const [targetValues, setTargetValues] = useState<
    Record<string, number | string>
  >({});

  if (!planTargets) {
    return null;
  }

  const tableHeaders = [
    ...planTargets.headers,
    { id: 0, header: 'Plan Opt In/Out', key: 'include' },
  ];

  const { rows: tableRows } = planTargets;

  const updatePlanogram = ({
    planRowId: id,
    planogramNo,
  }: {
    planRowId: string;
    planogramNo: string;
  }): void => {
    updatePlanPlanogram({ id: id, planogramNo });
  };

  const updateTargetValue = ({
    id,
    value,
  }: {
    id: string;
    value: number | string | undefined;
  }): void => {
    setTargetValues((targets) =>
      typeof value === 'undefined'
        ? omit(targets, [id])
        : { ...targets, [id]: value }
    );
  };

  const saveTargetValue = ({ id }: { id: string }) => {
    const value = targetValues[id];

    updateTargetValue({ id, value: undefined });

    if (isValidTarget(value)) {
      updatePlanTarget({ id, value: Number(value) });
    }
  };

  const getCellContent = (
    cell: CarbonCell<unknown>,
    row: PlanTargetRow | LocationPlanTargetRow
  ): ReactNode => {
    const { id } = row;

    switch (cell.info.header) {
      case 'include':
        return (
          <Toggle
            size="sm"
            labelText="Opt In"
            labelA="Out"
            labelB="In"
            toggled={row.include}
            id={`toggle_${id}`}
            onToggle={(include: boolean): void => {
              updatePlanInclusion({ id, include });
            }}
          />
        );

      case 'skuTarget': {
        const targetValue = targetValues[id];
        const cellValue = cell.value as number;

        if (typeof targetValue !== 'undefined') {
          return (
            <div className="number-picker">
              <NumberInput
                id={`target_${id}`}
                className="inline-number"
                min={1}
                max={99999}
                value={targetValue ?? cellValue}
                invalid={!isValidTarget(targetValues[id])}
                onChange={(_, { value }) => {
                  updateTargetValue({ id, value });
                }}
              />
              <Tooltip description="Save target" align="top" useButtonWrapper>
                <Save
                  data-testid="save-icon"
                  onClick={() => {
                    saveTargetValue({ id });
                  }}
                />
              </Tooltip>
            </div>
          );
        }

        return (
          <div className="number-picker">
            {cellValue}
            <div className="number-picker__edit-button">
              <Tooltip description="Edit target" align="top" useButtonWrapper>
                <Edit
                  data-testid="edit-icon"
                  onClick={() => {
                    updateTargetValue({ id, value: cellValue });
                  }}
                />
              </Tooltip>
            </div>
          </div>
        );
      }

      default: {
        return typeof cell.value === 'object'
          ? (cell.value as { value: ReactNode }).value
          : (cell.value as ReactNode);
      }
    }
  };

  return (
    <div className="PlanTargets">
      <DataTable headers={tableHeaders} rows={tableRows}>
        {({
          rows,
          headers,
          getRowProps,
          getTableProps,
          getTableContainerProps,
        }: {
          rows: CarbonSelectedRow[];
          headers: Header[];
          getRowProps;
          getTableProps;
          getTableContainerProps;
        }) => (
          <TableContainer {...getTableContainerProps()}>
            <Table {...getTableProps()} data-testid="plan-targets">
              <TableHead>
                <TableRow>
                  <TableExpandHeader aria-label="expand row" />
                  {headers.map((header) => (
                    <TableHeader key={header.key}>{header.header}</TableHeader>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.map((row, index) => {
                  const rowData = tableRows[index];

                  return (
                    <Fragment key={row.id}>
                      <TableExpandRow
                        {...getRowProps({ row })}
                        data-testid={`plan-row-${rowData.id}`}
                      >
                        {row.cells.map((cell) => (
                          <TableCell key={cell.id}>
                            {getCellContent(cell, rowData)}
                          </TableCell>
                        ))}
                      </TableExpandRow>
                      <TableExpandedRow
                        colSpan={headers.length + 2}
                        data-testid={`plan-expanded-row-${rowData.id}`}
                      >
                        <PlanogramTable
                          planRowId={rowData.id}
                          data={rowData.planograms}
                          value={rowData.referencePlanogramNo}
                          onChange={updatePlanogram}
                        />
                      </TableExpandedRow>
                    </Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
    </div>
  );
};

export default PlanTargets;
