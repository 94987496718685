/* eslint-disable indent */
import type { FunctionComponent } from 'react';
import { useContext, useEffect, useState } from 'react';
import {
  Button,
  InlineNotification,
  ToastNotification,
  InlineLoading,
} from '@carbon/react';
import { AppContext } from '../../providers/AppProvider';
import { ReportContext } from '../../providers/ReportProvider';
import type {
  FileData,
  ReportConfig,
  Visual,
  VisualData,
} from '../../reducers/ReportReducer';
import { EXPORT_DATA_STATUS, VisualType } from '../../reducers/ReportReducer';
import { getMeasureInfo } from '../../utils';
import { getVisual } from '../../utils/getVisual';
import {
  clickDownload,
  findVisualById,
  getDataFileKey,
  getFirstDropdownSelectionKey,
  getSKUGrid,
  getSkuGridApiUrl,
  hasSkuGrid,
  serverDataGridExport,
} from '../../utils/reportUtils';
import Fetch from '../Fetch';
import { loadExtras } from './ReportExtras';
import { VISUAL_CONTAINER_KEY } from '../../constants/values';
import { useAuth0 } from '@auth0/auth0-react';
import { UPDATE } from '../../constants/websocketMessages';
import { WebsocketContext } from '../../providers/WebsocketProvider';
import { ModalContext } from '../../providers/ModalProvider';
import ConditionalWrapper from '../ConditionalWrapper';
import { useNavigate } from 'react-router-dom';
import { editReportClick } from '../../constants/posthog';
import usePosthog from '../../utils/posthog';
import DataVisualSkeleton from './DataVisualSkeleton';
import { USER_TYPE } from '../../constants/metadata';
import type { CustomAction } from '../Charts/DataGrid';
import { CustomActionKey } from '../Charts/DataGrid';
import SharedDropdowns from './Dropdowns/SharedDropdowns';
import type { TimePeriod } from '../../reducers/HomeReducer';
import type {
  DataVisualDropdownsProps,
  ExtendedDropdownConfig,
} from './Dropdowns/DataVisualDropdowns';
import DataVisualDropdowns from './Dropdowns/DataVisualDropdowns';

interface VisualProps extends Visual {
  tab: number | string;
  visualData: VisualData;
  reportType: ReportType;
  reportTemplateId: string;
  reportTemplateIndex: number;
  filePath?: string;
  isHomepage?: boolean;
  visualsIndex?: number;
  visualHeader?: VisualHeader;
  globalSelections?: {
    label: string;
    selectedKey: number | string;
  }[];
  useDropdownsWrapper?: boolean;
  showExport?: boolean;
  exportAllCharts?: boolean;
  withoutBackground?: boolean;
  isVisible?: boolean;
  hideDropdowns?: boolean;
  onReceiveVisualConfig?: (time: TimePeriod[]) => void;
}

const splitArray = <T,>(
  data: T[],
  predicate: (value: T, currentIndex: number, array: T[]) => unknown
): [T[], T[]] =>
  data.reduce(
    (acc, value, currentIndex, array) =>
      predicate(value, currentIndex, array)
        ? [[...acc[0], value], acc[1]]
        : [acc[0], [...acc[1], value]],
    [[], []] as [T[], T[]]
  );

const reportWithoutDropdowns = (type: VisualType): boolean => {
  return [VisualType.DENDROGRAM, VisualType.CDT_DENDROGRAM].includes(type);
};
const DataVisual: FunctionComponent<VisualProps> = ({
  id,
  type,
  reportType,
  apiUrl,
  filePath,
  tab,
  hasMeasureDropdown,
  visualData,
  reportTemplateId,
  reportTemplateIndex,
  globalSelections,
  isHomepage,
  visualHeader,
  visualsIndex,
  showExport,
  exportAllCharts,
  withoutBackground,
  isVisible,
  isSecondaryAxisVisible,
  hideDropdowns,
  onReceiveVisualConfig,
}) => {
  const cacheKey = `${id}-${apiUrl}`;
  const [isSkuSelected, setIsSkuSelected] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const posthogEvent = usePosthog();
  const [fileDropdownConfig, setFileDropdownConfig] = useState<
    DropdownConfig[]
  >([]);
  const [exporting, setExporting] = useState(false);
  const {
    visualRefs,
    dropdownSelections,
    sharedDropdowns,
    reportConfig,
    visualsData,
    visualControlsRef,
    updateVisualsData,
    updateSingleSelection,
    updateReportConfig,
    updateDataFiles,
    updateFileDropdownSelection,
    updateSharedDropdowns,
    patchVisualsData,
  } = useContext(ReportContext);

  const isReportSplit =
    reportConfig.report_version === 'v2' &&
    reportType !== 'Customer Decision Tree';

  const skuGridVisualId = `${id}_sku-grid`;
  const currentId = isSkuSelected ? skuGridVisualId : id;
  const { updateModal } = useContext(ModalContext);
  const exportDataStatus = reportConfig.export_data?.[currentId]?.status;

  const {
    queryCache,
    user,
    bannerId,
    groupId,
    updateQueryCache,
    clearCacheForKey,
  } = useContext(AppContext);
  const { broadcastChannel } = useContext(WebsocketContext);
  const isSupplier = user.user_type === USER_TYPE.SUPPLIER;
  const [measure, setMeasure] = useState<Measure | null>(null);
  const fetchData = !visualData && !!queryCache[cacheKey];
  const visualDropdownSelection = dropdownSelections?.[tab]?.[currentId];

  const { files, ...sharedVisualData } =
    (isSkuSelected ? visualsData[skuGridVisualId] : visualData) || {};
  const currentVisualData = isReportSplit
    ? ({ ...sharedVisualData, ...files?.[visualDropdownSelection] } as FileData)
    : sharedVisualData;

  const dropdownSelection = isReportSplit
    ? (currentVisualData as FileData)?.fileDropdownSelections
    : visualDropdownSelection;
  const controlsContainerRef = visualControlsRef[id];

  const skuLevel = (
    reportConfig?.parameters?.template_requests[reportTemplateIndex]
      ?.analytic_engine_params as AnalyticEngineParams
  )?.sku_lvl as string | undefined;

  const hasChildGrid = hasSkuGrid(
    reportConfig.configuration.visuals[reportTemplateId],
    visualData,
    skuLevel
  );

  const isSkuGridReady =
    (reportConfig.report_files?.[reportTemplateId].includes('sku-grid') &&
      !!visualsData[skuGridVisualId]) ||
    !!reportConfig.error_message;

  useEffect(() => {
    if ((currentVisualData as FileData)?.visualDropdownConfig) {
      const dropdowns = currentVisualData?.visualDropdownConfig?.filter(
        (dropdown) => {
          return !files?.[visualDropdownSelection]?.sharedDropdown?.some(
            (sdd) => sdd.id === dropdown.id
          );
        }
      );
      setFileDropdownConfig(dropdowns);
    }
  }, [(currentVisualData as FileData)?.visualDropdownConfig]);

  const visualDropdowns = currentVisualData?.dropdownConfig?.filter(
    (dropdown) => {
      return (
        !sharedDropdowns[tab]?.some((sdd) => sdd.id === dropdown.id) &&
        !globalSelections?.some(({ label }) => dropdown.label === label)
      );
    }
  );

  const combinedDropdowns: ExtendedDropdownConfig[] = [
    ...(isReportSplit
      ? (fileDropdownConfig ?? []).map((dropdown) => ({
          dropdown,
          dropdownConfig: fileDropdownConfig,
          dropdownSelection: (currentVisualData as FileData)
            .fileDropdownSelections,
        }))
      : []),
    ...(visualDropdowns ?? []).map((dropdown) => ({
      dropdown,
      dropdownConfig: (currentVisualData as FileData).dropdownConfig,
      dropdownSelection: visualDropdownSelection,
    })),
  ];

  const [dataVisualDropdowns, gridDropdowns] = splitArray(
    combinedDropdowns,
    ({ dropdown: { align } }) =>
      type !== VisualType.CELL_GRID || (!visualHeader && align !== 'right')
  );

  const [gridTableDropdowns, gridToolbarDropdowns] = splitArray(
    gridDropdowns,
    ({ dropdown: { align } }) => align !== 'right'
  );

  const shouldRenderDropdowns =
    !hideDropdowns &&
    currentVisualData &&
    (visualDropdownSelection ||
      (currentVisualData as FileData).fileDropdownSelections) &&
    dataVisualDropdowns.length > 0;

  useEffect(() => {
    if (!visualDropdownSelection && currentVisualData?.dropdownConfig) {
      let firstKey = getFirstDropdownSelectionKey(
        currentVisualData?.dropdownConfig
      );
      if (globalSelections) {
        const newKey = firstKey?.split('_') ?? [];
        firstKey = globalSelections
          .reduce((acc, { label, selectedKey }) => {
            const dropdownIndex = currentVisualData?.dropdownConfig?.findIndex(
              (dropdown) => label === dropdown.label
            );
            acc[dropdownIndex] = selectedKey.toString();
            return acc;
          }, newKey)
          .join('_');
      }
      updateSingleSelection(tab, currentId, firstKey);
    }
  }, [currentVisualData?.dropdownConfig, globalSelections]);

  useEffect(() => {
    if (globalSelections && visualDropdownSelection) {
      const splitKey = visualDropdownSelection?.split('_') ?? [];
      const newKey = globalSelections
        .reduce((acc, { label, selectedKey }) => {
          const dropdownIndex = currentVisualData?.dropdownConfig?.findIndex(
            (dropdown) => label === dropdown.label
          );
          acc[dropdownIndex] = selectedKey.toString();
          return acc;
        }, splitKey)
        .join('_');
      updateSingleSelection(tab, currentId, newKey);
    }
  }, [globalSelections]);

  useEffect(() => {
    const fileVisualData = currentVisualData as FileData;
    if (
      isReportSplit &&
      fileVisualData.visualDropdownConfig &&
      visualDropdownSelection &&
      !fileVisualData.fileDropdownSelections
    ) {
      const firstKey = getFirstDropdownSelectionKey(
        fileVisualData.visualDropdownConfig
      );
      updateFileDropdownSelection(currentId, firstKey || '1');
    }
  }, [currentVisualData]);

  useEffect(() => {
    const { visualDropdownConfig, dropdownConfig } =
      (currentVisualData as FileData) || {};
    const dropdowns = isReportSplit ? visualDropdownConfig : dropdownConfig;
    if (
      (hasMeasureDropdown &&
        currentVisualData &&
        dropdowns &&
        dropdownSelection) ||
      currentVisualData?.measure
    ) {
      if (currentVisualData?.measure) {
        setMeasure(currentVisualData.measure);
      } else {
        const data = currentVisualData as FileData;
        const selectedMeasure = getMeasureInfo(
          data,
          dropdownSelection,
          isReportSplit
        );
        setMeasure(selectedMeasure);
      }
    }
  }, [currentVisualData, visualDropdownSelection, hasMeasureDropdown]);

  const isAuthor = user.id === reportConfig.user_id;

  const handleGridServerExport = async (reportName: string) => {
    const visualApiUrl = findVisualById(
      reportConfig.configuration.visuals[reportTemplateId],
      id
    )?.apiUrl;
    if (visualApiUrl) {
      setExporting(true);
      const token = await getAccessTokenSilently();
      const fileApiUrl = isSkuSelected
        ? getSkuGridApiUrl(visualApiUrl)
        : visualApiUrl;
      await serverDataGridExport({
        visualApiUrl: fileApiUrl,
        reportName,
        globalSelections,
        bannerId,
        groupId,
        token,
        dropdownConfig: currentVisualData.dropdownConfig,
        reportId: reportConfig.run_id,
        visualId: currentId,
      });
      setExporting(false);
    }
  };

  const onMessage = (event: MessageEvent) => {
    const response = JSON.parse(event.data);

    const { action, data } = response;
    if (action === UPDATE && data.run_id === reportConfig.run_id) {
      switch (data.type) {
        case 'report_files': {
          const { report_files } = data;
          updateReportConfig({ ...reportConfig, report_files });
          break;
        }
        case 'error_message': {
          const { error_message } = data;
          updateReportConfig({ ...reportConfig, error_message });
          break;
        }
        case 'export-grid': {
          const { status, url, id } = data;
          updateReportConfig({
            ...reportConfig,
            export_data: {
              ...reportConfig.export_data,
              [id]: { url, status },
            },
          });
          const name = visualHeader?.title ?? reportConfig.report_name;
          if (status === EXPORT_DATA_STATUS.COMPLETED) {
            clickDownload(url, name, 'xlsx', user.id, groupId, origin);
          }
          break;
        }
      }
    }
  };

  useEffect(() => {
    if (broadcastChannel) {
      broadcastChannel.addEventListener('message', onMessage);
    }

    return () => broadcastChannel.removeEventListener('message', onMessage);
  }, [broadcastChannel]);

  const shouldFetchSKUGrid = (
    skuCache: number,
    type: VisualType,
    reportConfig: ReportConfig
  ) => {
    const isSKUGridCached = skuCache === bannerId;

    return (
      !isSKUGridCached &&
      type === VisualType.CELL_GRID &&
      reportConfig?.report_files?.[reportTemplateId].includes('sku-grid') &&
      hasChildGrid &&
      !reportConfig.error_message
    );
  };

  useEffect(() => {
    const skuApiUrl = getSkuGridApiUrl(apiUrl);
    const skuCacheKey = `sku-grid-${skuApiUrl}`;
    const requestSKUGrid = async () => {
      const token = await getAccessTokenSilently();
      try {
        const response = await getSKUGrid(skuApiUrl, token, isReportSplit);
        updateVisualsData(skuGridVisualId, response);
        updateQueryCache(skuCacheKey, bannerId);
      } catch {
        updateModal({
          type: 'error',
          title: 'Something went wrong',
          body: "There was an error loading the SKU's data. Please try again, or refresh your browser.",
        });
      }
    };

    if (shouldFetchSKUGrid(queryCache[skuCacheKey], type, reportConfig)) {
      requestSKUGrid();
    }
    return () => {
      clearCacheForKey(skuCacheKey);
    };
  }, [reportConfig?.report_files, hasChildGrid]);

  const toggleSkuGrid = () => {
    setIsSkuSelected(!isSkuSelected);
  };

  const visualWrapper = (children: JSX.Element[]) => {
    const path = filePath ?? apiUrl;
    const fileApiUrl = isSkuSelected ? getSkuGridApiUrl(path) : path;
    const fileCacheKey = `${id}-${fileApiUrl}`;
    return (
      <Fetch
        cacheKey={`${fileCacheKey}-${visualDropdownSelection}`}
        initialData={null}
        apiUrl={`${fileApiUrl}/${getDataFileKey(visualDropdownSelection)}`}
        loadingComponent={
          <DataVisualSkeleton
            className={
              isHomepage && type === VisualType.CARDS
                ? 'DataVisual__cards-loader'
                : ''
            }
          />
        }
        requestCanBeAborted
        alwaysFetchOnMount={
          !files?.[visualDropdownSelection] &&
          !!queryCache[`${fileCacheKey}-${visualDropdownSelection}`]
        }
        onReceiveData={(response) => {
          if (response) {
            updateDataFiles(currentId, response, visualDropdownSelection);
          }
        }}
        hideChildrenUntilFetched
      >
        {children}
      </Fetch>
    );
  };

  const handleEdit = () => {
    posthogEvent(editReportClick, {
      origin: reportConfig.error_message,
    });
    const { section, url_route, run_id } = reportConfig;
    navigate(`/modules/${section}/${url_route}?edit=${run_id}`);
  };

  const receiveVisualConfig = (response) => {
    const {
      shared_dropdown,
      dropdown_config,
      sticky_columns,
      is_visible,
      is_searchable,
      visual_header,
      time,
      ...responseData
    } = response;
    const dropdowns = isHomepage
      ? {
          sharedDropdown: shared_dropdown,
          dropdownConfig: dropdown_config,
          stickyColumns: sticky_columns,
          isVisible: is_visible,
          isSearchable: is_searchable,
          visualHeader: visual_header,
        }
      : {};
    updateVisualsData(id, {
      ...responseData,
      ...dropdowns,
    });
    if (isHomepage && !sharedDropdowns[tab]) {
      updateSharedDropdowns(tab, dropdowns.sharedDropdown);
    }
    if (time) {
      onReceiveVisualConfig?.(
        time.sort((a, b) => (a.label < b.label ? -1 : 1))
      );
    }
  };

  const visualDropdownProps: Omit<DataVisualDropdownsProps, 'dropdowns'> = {
    tab,
    id: currentId,
    controlsContainerRef: controlsContainerRef?.current,
    useDropdownsWrapper: !!visualHeader,
    isReportSplit,
    currentVisualData: currentVisualData as FileData,
    visualDropdownSelection,
    globalSelections,
  };

  const gridCustomToolbarActions: CustomAction[] | undefined =
    type === VisualType.CELL_GRID
      ? [
          isHomepage
            ? {
                key: CustomActionKey.SHARED_DROPDOWN,
                element: (
                  <SharedDropdowns
                    tab={tab}
                    visualIds={
                      reportConfig?.configuration?.switchers?.[
                        reportTemplateId
                      ][visualsIndex]?.visualContent
                    }
                    globalSelections={globalSelections}
                    hideLabels
                  />
                ),
              }
            : null,
          {
            key: CustomActionKey.VISUAL_DROPDOWNS_TOOLBAR,
            element: (
              <DataVisualDropdowns
                {...visualDropdownProps}
                dropdowns={gridToolbarDropdowns}
                hideLabel
              />
            ),
          },
        ].filter(Boolean)
      : undefined;

  const gridCustomTableActions: CustomAction[] | undefined =
    gridTableDropdowns.length > 0
      ? [
          {
            key: CustomActionKey.VISUAL_DROPDOWNS_TABLE,
            element: (
              <DataVisualDropdowns
                {...visualDropdownProps}
                dropdowns={gridTableDropdowns}
              />
            ),
          },
        ]
      : undefined;

  const dataGridNotificationToastOptions =
    exportDataStatus === EXPORT_DATA_STATUS.FAILED
      ? {
          subtitle: 'Something went wrong',
          caption: 'Please try again',
          kind: 'error',
        }
      : {
          subtitle: 'Loading...',
          caption: 'This may take up 3 minutes',
          kind: 'info',
        };

  return (
    <Fetch
      cacheKey={cacheKey}
      initialData={null}
      loadingMessage="Loading report config..."
      loadingComponent={
        isHomepage && (
          <DataVisualSkeleton
            className={
              type === VisualType.CARDS ? 'DataVisual__cards-loader' : ''
            }
          />
        )
      }
      apiUrl={`${apiUrl}${isReportSplit ? '/config' : ''}`}
      alwaysFetchOnMount={fetchData}
      requestCanBeAborted
      onReceiveData={(response) => {
        if (response) {
          receiveVisualConfig(response);
        }
      }}
    >
      {hasChildGrid &&
        type === VisualType.CELL_GRID &&
        reportConfig.error_message && (
          <div className="DataVisual__notification-wrapper">
            <ToastNotification
              aria-label="error sku level notification"
              kind="warning"
              lowContrast
              title="Error SKU level report"
              subtitle={reportConfig.error_message}
              className="DataVisual__sku-grid-toast"
            >
              {isAuthor && (
                <Button
                  onClick={handleEdit}
                  kind="secondary"
                  as="div"
                  size="sm"
                >
                  Edit Report
                </Button>
              )}
            </ToastNotification>
          </div>
        )}
      {((exportDataStatus &&
        exportDataStatus !== EXPORT_DATA_STATUS.COMPLETED) ||
        exporting) && (
        <ToastNotification
          {...dataGridNotificationToastOptions}
          className={`DataGrid__notification-toast ${exportDataStatus?.toLowerCase()}`}
          data-testid="data-grid-notification-toast"
          title="Table Data Export All"
          size="sm"
          lowContrast
        >
          {exportDataStatus !== EXPORT_DATA_STATUS.FAILED && (
            <div className="DataGrid__notification-loading">
              <InlineLoading />
            </div>
          )}
        </ToastNotification>
      )}
      <div
        className={`visual-container ${
          currentVisualData?.cards ||
          currentVisualData?.summary ||
          withoutBackground
            ? 'no-background'
            : ''
        }${isHomepage ? ' homepage' : ''}`.trim()}
        ref={(el) => (visualRefs.current[`${VISUAL_CONTAINER_KEY}-${id}`] = el)}
      >
        {shouldRenderDropdowns && (
          <DataVisualDropdowns
            {...visualDropdownProps}
            dropdowns={dataVisualDropdowns}
          />
        )}
        <ConditionalWrapper
          wrapper={visualWrapper}
          condition={isReportSplit && visualDropdownSelection}
        >
          {loadExtras(reportType)}
          {Object.keys(currentVisualData).length > 0 &&
            (dropdownSelection || reportWithoutDropdowns(type)) &&
            getVisual(id, type, currentVisualData, dropdownSelection, {
              measure,
              reportConfig,
              isAuthor,
              isSupplier,
              isSkuSelected,
              isSkuGridReady,
              hasChildGrid,
              reportTemplateId,
              reportTemplateIndex,
              visualDropdownSelection,
              visualHeader,
              gridCustomToolbarActions,
              gridCustomTableActions,
              tab,
              showExport,
              exportAllCharts,
              isVisible,
              isSecondaryAxisVisible,
              toggleSkuGrid,
              patchVisualsData,
              handleGridServerExport,
            })}
          {currentVisualData && currentVisualData.error && (
            <InlineNotification
              title={currentVisualData.error.message}
              kind="warning-alt"
              hideCloseButton
              lowContrast
            >
              {currentVisualData.error.reason}
            </InlineNotification>
          )}
        </ConditionalWrapper>
      </div>
    </Fetch>
  );
};

export default DataVisual;
