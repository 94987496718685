/* eslint-disable indent */
import type { ElementRef, FunctionComponent } from 'react';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Repeat } from '@carbon/icons-react';
import { AssortmentContext } from '../../providers/AssortmentProvider';
import { ReportContext } from '../../providers/ReportProvider';
import {
  addAndRemoveItems,
  formatNumber,
  getTextColor,
} from '../../utils/reportUtils';
import type { HighlightedCell } from './DataGrid';
import DataGrid from './DataGrid';
import { AppContext } from '../../providers/AppProvider';
import { CONTINUOUS_SCALE } from '../../constants/palettes';
import type { HighlightLegendItem } from '../../reducers/AssortmentReducer';
import { SUBS_HEADER_KEYS, USER_TYPE } from '../../constants/metadata';

interface AssortmentGridProps {
  dropdownKey: string;
  reportTemplateId: string;
  reportTemplateIndex: number;
}

export const SUBSTITUTE_HIGHLIGHT_KEY = 'substitutes';

const AssortmentGrid: FunctionComponent<AssortmentGridProps> = ({
  dropdownKey,
  reportTemplateId,
  reportTemplateIndex,
}) => {
  const { user } = useContext(AppContext);
  const { visualsData, reportConfig, patchVisualsData } =
    useContext(ReportContext);
  const {
    assortmentData,
    selectedProducts,
    highlight,
    highlightLegend,
    resetSelection,
    updateSelectedProducts,
    saveAssortmentData,
    updateHighlightLegend,
    updateHighlight,
  } = useContext(AssortmentContext);
  const isAuthor = reportConfig.user_id === user.id;
  const isSupplier = user.user_type === USER_TYPE.SUPPLIER;

  const dataGridRef = useRef<ElementRef<typeof DataGrid>>();

  const [highlightedCells, setHighlightedCells] = useState<{
    [key: string]: HighlightedCell;
  }>(null);

  const [rows, setRows] = useState([]);

  const orderedData = useMemo(() => {
    if (!highlight.display) {
      return visualsData[1];
    }
    const data = visualsData[1];
    const headers = data.headers[1];
    const highlightedId = headers.find((h) => h.key === highlight.by).id;

    const headerIndex = data.isVisible.indexOf(highlightedId);

    const columnOrder = [...data.isVisible];
    const firstColIndex = data.stickyColumns.length;
    columnOrder.splice(headerIndex, 1);
    columnOrder.splice(firstColIndex, 0, highlightedId);

    return {
      ...data,
      isVisible: columnOrder,
      stickyColumns: [...visualsData[1].stickyColumns, highlightedId],
    };
  }, [highlight, visualsData[1]]);

  const customSortHeaders = (a: Header, b: Header): number => {
    return (
      orderedData.isVisible.indexOf(a.id) - orderedData.isVisible.indexOf(b.id)
    );
  };

  useEffect(() => {
    const highlightMap: {
      [key: string]: HighlightedCell;
    } = {};
    if (highlight.display) {
      const selected = highlightLegend?.find(
        (entry) => entry.key === highlight.by
      );
      assortmentData.forEach((d) => {
        const cellId = `${d.id}:PRODUCT`;
        const legendValue = d[selected?.key];

        if (highlight.type === 'discrete') {
          const colour = selected.items.find(
            (i) => i.label === legendValue
          ).colour;

          highlightMap[cellId] = {
            color: getTextColor(colour),
            backgroundColor: colour,
            tooltip: `${selected.label}: ${legendValue}`,
          };
        } else if (
          highlight.type === 'continuous' ||
          highlight.type === 'index'
        ) {
          const colour =
            selected.items.find(
              (i) =>
                legendValue.value >= i.range.min &&
                legendValue.value <= i.range.max
            )?.colour || '#fff';

          highlightMap[cellId] = {
            color: getTextColor(colour),
            backgroundColor: colour,
            tooltip: `${selected.label}: ${formatNumber(legendValue)}`,
          };
        }
      });
    } else if (highlight.by === SUBSTITUTE_HIGHLIGHT_KEY) {
      const product = assortmentData.find(
        (row) => row.PRODUCT_ID === highlight.targetProductId
      );
      const {
        subs_1_name,
        subs_1_tfr,
        subs_2_name,
        subs_2_tfr,
        subs_3_name,
        subs_3_tfr,
        subs_4_name,
        subs_4_tfr,
        subs_5_name,
        subs_5_tfr,
      } = product;
      const subs = [
        { label: subs_1_name, ...subs_1_tfr },
        { label: subs_2_name, ...subs_2_tfr },
        { label: subs_3_name, ...subs_3_tfr },
        { label: subs_4_name, ...subs_4_tfr },
        { label: subs_5_name, ...subs_5_tfr },
      ].filter((s) => s.label !== '-');

      const subsRows = subs.map((row) =>
        assortmentData.find((prod) => row.label === prod.PRODUCT)
      );

      subsRows.forEach((row, index) => {
        const cellId = `${row.id}:PRODUCT`;
        const colour = CONTINUOUS_SCALE[index * 2];
        highlightMap[cellId] = {
          color: getTextColor(colour),
          backgroundColor: colour,
          tooltip: `Substitution transfer ${formatNumber({
            value: subs[index].value,
            format: 'percent2',
          })}`,
        };
      });

      assortmentData.forEach((row) => {
        Object.keys(row).forEach((key) => {
          const cellId = `${row.id}:${key}`;
          if (key !== 'PRODUCT' && row[key] === product.PRODUCT) {
            highlightMap[cellId] = {
              showBorder: true,
            };
          }
        });
      });
      const legendItems: HighlightLegendItem[] =
        subs.length === 0
          ? [
              {
                label: 'No substitutable products',
                colour: '#fff',
              },
            ]
          : subs.map((sub, index) => {
              const colour = CONTINUOUS_SCALE[index * 2];
              let info;

              if (index === 0) {
                info = '(Most)';
              } else if (index === subs.length - 1) {
                info = '(Least)';
              }

              return {
                label: sub.label,
                colour: colour,
                range: { min: sub.value, max: Infinity },
                info,
              };
            });

      const subsLegend = highlightLegend.find(
        (leg) => leg.key === SUBSTITUTE_HIGHLIGHT_KEY
      );

      const newLegend = highlightLegend.map((entry) => {
        if (entry.key === SUBSTITUTE_HIGHLIGHT_KEY) {
          return {
            ...subsLegend,
            items: legendItems,
          };
        } else {
          return entry;
        }
      });
      updateHighlightLegend(newLegend);
    }
    setHighlightedCells(highlightMap);
  }, [highlight]);

  const selectRows = (indexes: number[], add?: boolean) => {
    resetSelection();
    const productIds = assortmentData
      .filter((_, idx) => indexes.includes(idx))
      .map((product) => product.PRODUCT_ID);

    updateSelectedProducts(
      add ? addAndRemoveItems(selectedProducts, productIds) : productIds
    );
  };

  useEffect(() => {
    if (highlight.display) {
      setRows(assortmentData);
    } else {
      const newRows = assortmentData.map((row) => {
        return {
          ...row,
          PRODUCT: {
            value: row.PRODUCT,
            action: {
              onClick: async (cellId: string) => {
                if (highlight.targetProductId !== row.PRODUCT_ID) {
                  updateHighlight({
                    cellId,
                    display: false,
                    title: 'Substitutability Strength',
                    type: 'continuous',
                    by: SUBSTITUTE_HIGHLIGHT_KEY,
                    label: SUBSTITUTE_HIGHLIGHT_KEY,
                    targetProductId:
                      highlight.targetProductId !== row.PRODUCT_ID
                        ? row.PRODUCT_ID
                        : null,
                  });
                } else {
                  updateHighlight({
                    display: false,
                    type: 'continuous',
                    by: null,
                    label: null,
                    targetProductId: null,
                  });
                }
                await dataGridRef.current.showHeaders(SUBS_HEADER_KEYS);

                dataGridRef.current.scrollHeaderIntoView();
              },
              icon: <Repeat />,
              tooltip: 'View substitutes',
            },
          },
        };
      });
      setRows(newRows);
    }
  }, [highlight, assortmentData]);

  return (
    <div className="assortment-grid">
      <DataGrid
        ref={dataGridRef}
        headerData={orderedData.headers[dropdownKey]}
        rowData={rows}
        stickyColumns={orderedData.stickyColumns}
        visibleColumns={orderedData.isVisible}
        collapsed
        showExport={false}
        isSortable={false}
        showPagination={false}
        columnWidths={orderedData.columnWidths}
        visualData={visualsData[1]}
        id={1}
        reportTemplateId={reportTemplateId}
        reportTemplateIndex={reportTemplateIndex}
        resizable
        isAuthor={isAuthor}
        reportConfig={reportConfig}
        patchVisualsData={patchVisualsData}
        onSave={isAuthor && !isSupplier && saveAssortmentData}
        clickedCellId={highlight.cellId}
        highlightedRows={selectedProducts.map((sku) =>
          assortmentData.map((row) => row.PRODUCT_ID).indexOf(sku)
        )}
        highlightedCells={highlightedCells}
        onSelectRows={(rowIndexes, add) => selectRows(rowIndexes, add)}
        customSortHeaders={customSortHeaders}
      />
    </div>
  );
};

export default AssortmentGrid;
