/* eslint-disable indent */

import {
  DataTable,
  RadioButton,
  TableContainer,
  Table,
  TableHead,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
} from '@carbon/react';
import type { FunctionComponent, ReactNode } from 'react';
import type {
  PlanogramGrid,
  PlanogramRow,
} from '../../reducers/OptimiserFormReducer';

interface PlanogramTable {
  readonly planRowId: string;
  readonly data: PlanogramGrid;
  readonly value: PlanogramRow['planogramNo'];
  readonly onChange: (row: {
    readonly planRowId: string;
    readonly planogramNo: PlanogramRow['planogramNo'];
  }) => void;
}

const PlanogramTable: FunctionComponent<PlanogramTable> = (props) => {
  const { data: planogramsTable, planRowId, value, onChange } = props;

  const updateSelection = (planogramNo: PlanogramRow['planogramNo']) => {
    onChange({ planRowId, planogramNo });
  };

  const getCellContent = ({
    cell,
    row,
  }: {
    cell: CarbonCell<unknown>;
    row: PlanogramRow;
  }): ReactNode => {
    if (cell.info.header === 'planogramName') {
      return (
        <div className="flex-cell">
          <RadioButton
            labelText=""
            value={row.planogramNo}
            checked={value === row.planogramNo}
            onChange={updateSelection}
            name={`planogram_${planRowId}`}
            id={`planogram_${planRowId}_${row.planogramNo}`}
          />

          {cell.value as string}
        </div>
      );
    }

    return typeof cell.value === 'object'
      ? (cell.value as { value: ReactNode }).value
      : (cell.value as ReactNode);
  };

  return (
    <div className="Planograms">
      <DataTable
        headers={planogramsTable.headers}
        rows={planogramsTable.rows}
        useStaticWidth
      >
        {({
          rows,
          headers,
          getRowProps,
          getTableProps,
          getTableContainerProps,
        }: {
          rows: CarbonSelectedRow[];
          headers: Header[];
          getRowProps;
          getTableProps;
          getTableContainerProps;
        }) => (
          <TableContainer {...getTableContainerProps()}>
            <Table {...getTableProps()} data-testid="planograms">
              <TableHead>
                <TableRow>
                  {headers.map(({ key, header }) => (
                    <TableHeader key={key}>{header}</TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => {
                  const rowData = planogramsTable.rows[index];

                  return (
                    <TableRow
                      key={row.id}
                      {...getRowProps({ row })}
                      data-testid={`planogram-row-${rowData.planogramNo}`}
                    >
                      {row.cells.map((cell) => (
                        <TableCell key={cell.id}>
                          {getCellContent({ cell, row: rowData })}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
    </div>
  );
};

export default PlanogramTable;
