/* eslint-disable indent */

import { useContext, useMemo, useState } from 'react';
import { Button } from '@carbon/react';
import { ChevronLeft, DocumentView } from '@carbon/icons-react';
import Fetch from '../Fetch';
import { AppContext } from '../../providers/AppProvider';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import type { ReportConfig } from '../../reducers/ReportReducer';
import ReportMetaData from '../Report/ReportMetaData';
import { SidePanelContext } from '../../providers/SidePanelProvider';
import { getReportApiUrl } from '../../utils/reportUtils';
import '../../styles/components/optimise.scss';
import OptimiserConfiguration from './OptimiserConfiguration';
import OptimiserFormProvider from '../../providers/OptimiserFormProvider';
import { OptimiserMode } from '../../reducers/OptimiserFormReducer';
import { getOptimiserMode, hasPlanograms } from '../../utils/CDTUtils';

const getMode = ({
  reportConfig,
  reportTemplateId,
  isEditing,
}: {
  reportConfig: ReportConfig;
  reportTemplateId: string;
  isEditing: boolean;
}): OptimiserMode | undefined => {
  if (!hasPlanograms(reportConfig)) {
    return OptimiserMode.NonPlan;
  }

  if (!isEditing) {
    return undefined;
  }

  return getOptimiserMode(reportConfig, reportTemplateId);
};

const Optimise = () => {
  const { bannerId, groupId } = useContext(AppContext);
  const { toggleSidePanelExpand, renderSidePanelContent } =
    useContext(SidePanelContext);
  const navigate = useNavigate();
  const { reportId } = useParams();
  const [searchParams] = useSearchParams();
  const [reportConfig, setReportConfig] = useState<ReportConfig>(null);

  const isEditing = searchParams.get('mode') === 'edit';

  const { section, url_route, run_id, parameters, configuration } =
    reportConfig || {};
  const reportTemplateIndex = 0;
  const reportTemplateId = `${parameters?.template_id}_${reportTemplateIndex}`;

  const reportApiUrl = useMemo(
    () =>
      configuration?.visuals &&
      getReportApiUrl(configuration.visuals[reportTemplateId][0].apiUrl),
    [configuration?.visuals?.[reportTemplateId][0].apiUrl]
  );

  const cdtPath = `${bannerId}/user-groups/${groupId}/${reportApiUrl}/`;

  const goBack = () => navigate(`/workspace/view-report/${run_id}`);

  const handleEdit = () => {
    navigate(`/modules/${section}/${url_route}?edit=${run_id}`);
  };

  const handleDuplicate = () => {
    navigate(`/modules/${section}/${url_route}?duplicate=${run_id}`);
  };

  const handleCancel = () => {
    toggleSidePanelExpand(false);
  };

  const viewMetaData = () =>
    renderSidePanelContent(
      <ReportMetaData
        reportConfig={reportConfig}
        handleEdit={handleEdit}
        handleCancel={handleCancel}
        handleDuplicate={handleDuplicate}
        reportTemplateIndex={0}
      />,
      null,
      false
    );

  return (
    <div className="Optimise">
      <div className="Optimise__header">
        <Button
          kind="ghost"
          iconDescription="Go back"
          renderIcon={ChevronLeft}
          hasIconOnly
          size="sm"
          disabled={!run_id}
          onClick={goBack}
        />
        <div className="heading-06">Assortment Optimiser Request</div>
        <Button
          kind="secondary"
          renderIcon={DocumentView}
          hasIconOnly
          iconDescription="Report summary"
          disabled={!reportConfig}
          onClick={viewMetaData}
          size="sm"
        />
      </div>
      <Fetch
        apiUrl={`/reports/${bannerId}/user-groups/${groupId}/data?run_id=${reportId}`}
        alwaysFetchOnMount={!reportConfig}
        onReceiveData={(data) => {
          if (data) {
            setReportConfig(data[0]);
          }
        }}
        loadingMessage="Loading report config..."
        initialData={null}
        hideChildrenUntilFetched
      >
        {reportApiUrl && (
          <OptimiserFormProvider
            cdtPath={cdtPath}
            initialMode={getMode({
              reportConfig,
              reportTemplateId,
              isEditing,
            })}
          >
            <OptimiserConfiguration
              reportApiUrl={reportApiUrl}
              isEditing={isEditing}
            />
          </OptimiserFormProvider>
        )}
      </Fetch>
    </div>
  );
};

export default Optimise;
