import type { FunctionComponent } from 'react';
import { useContext, useMemo, useRef, useState } from 'react';
import { InlineLoading } from '@carbon/react';
import { ChevronLeft } from '@carbon/icons-react';
import type { ResponsePayload } from '../Fetch';
import Fetch from '../Fetch';
import { CACHE_KEY } from '../../constants/api';
import type { GenAIUserQuestion, GenAIPanelProps } from './GenAIPanel.types';
import type {
  GenAIAnswer,
  QuestionOption,
} from '../GenAIAdmin/GenAIAdmin.types';
import '../../styles/components/genAIPanel.scss';
import AIIcon from '../../assets/icons/AIIcon';
import apiRequest from '../../api';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { AppContext } from '../../providers/AppProvider';
import type { GenAIResponse } from '../../types/apiTypes';
import GenAIAnswerActions from './GenAIAnswerActions/GenAIAnswerActions';

const GenAIPanel: FunctionComponent<GenAIPanelProps> = ({
  selectedCategory,
  configFiles,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { bannerId, queryCache } = useContext(AppContext);
  const [questions, setQuestions] = useState<QuestionOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeQuestion, setActiveQuestion] = useState<QuestionOption | null>(
    null
  );
  const [answer, setAnswer] = useState<GenAIAnswer>({});
  const abortControllerRef = useRef<AbortController | null>(null);
  const inactiveQuestions = useMemo(
    () => questions.filter((question) => question.id !== activeQuestion?.id),
    [activeQuestion]
  );

  const handleGoBack = () => {
    setActiveQuestion(null);
    setAnswer({});
    abortControllerRef.current.abort();
    setIsLoading(false);
  };

  const submitQuestion = async (question: QuestionOption) => {
    setIsLoading(true);
    setActiveQuestion(question);
    setAnswer({});
    abortControllerRef.current = new AbortController();

    const payload: GenAIUserQuestion = {
      question_id: question.id,
      req_question_category: selectedCategory.label,
      config_files: configFiles,
    };

    try {
      const token = await getAccessTokenSilently();
      const response = await apiRequest<ResponsePayload<GenAIResponse>>(
        '/genai',
        'POST',
        token,
        payload,
        abortControllerRef.current.signal
      );

      const {
        status_code,
        body: { message, request_id },
      } = response.data;

      setAnswer({
        type: status_code === 200 ? 'success' : 'error',
        message: message,
        requestId: request_id,
      });
    } catch (error) {
      if (!axios.isCancel(error)) {
        setAnswer({
          type: 'error',
          message:
            'There was an error. Please try refreshing your browser. If the issue persists, please contact the helpdesk.',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getQuestionsList = (): JSX.Element => {
    return questions.length ? (
      <>
        <div className="GenAIPanel__header">
          <p className="GenAIPanel__header-title">
            Hello, I am OneViu AI
            <AIIcon />
          </p>
          <p className="GenAIPanel__header-text">
            What would you like to find out more about today?
          </p>
        </div>
        {questions.map((question: QuestionOption) => (
          <button
            key={question.id}
            className="GenAIPanel__button"
            disabled={isLoading}
            onClick={() => submitQuestion(question)}
          >
            {question.question}
          </button>
        ))}
      </>
    ) : (
      <p>Sorry, no available questions at the moment</p>
    );
  };

  return (
    <div className="GenAIPanel">
      <Fetch
        initialData={questions}
        alwaysFetchOnMount={
          !questions.length || !queryCache[CACHE_KEY.GENAI_QUESTIONS]
        }
        onReceiveData={(data: QuestionOption[]) => {
          if (data) {
            setQuestions(data);
          }
        }}
        apiUrl={`/genai/questions?is_active=true&tdp=${bannerId}`}
        cacheKey={CACHE_KEY.GENAI_QUESTIONS}
        loadingMessage="Loading OneViuAI data..."
      >
        {!activeQuestion ? (
          getQuestionsList()
        ) : (
          <div className="flex align-center gap--medium">
            <ChevronLeft
              data-testid="go-back-button"
              onClick={handleGoBack}
              size={24}
              className="GenAIPanel__button-back"
            />
            <div className="GenAIPanel__message">{activeQuestion.question}</div>
          </div>
        )}
        {isLoading && (
          <InlineLoading
            status="active"
            iconDescription="Loading"
            description="Loading OneViu AI response..."
          />
        )}
        {answer?.message && (
          <>
            <p
              className={`GenAIPanel__answer GenAIPanel__answer-${answer.type}`}
            >
              {answer?.type === 'success' && (
                <span className="GenAIPanel__answer-icon">
                  <AIIcon size={22} />
                </span>
              )}
              {answer.message}
            </p>
            {answer.type === 'success' && (
              <GenAIAnswerActions
                message={answer?.message}
                requestId={answer?.requestId}
                questionId={activeQuestion?.id}
              />
            )}
            {inactiveQuestions.length > 0 && (
              <div className="GenAIPanel__footer">
                <p className="GenAIPanel__footer-title">
                  <AIIcon size={16} /> Here are other questions
                </p>
                <div className="GenAIPanel__footer-action">
                  {inactiveQuestions.map((inactiveQuestion: QuestionOption) => (
                    <button
                      key={inactiveQuestion.id}
                      className="GenAIPanel__button"
                      disabled={isLoading}
                      onClick={() => submitQuestion(inactiveQuestion)}
                    >
                      {inactiveQuestion.question}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </Fetch>
    </div>
  );
};

export default GenAIPanel;
