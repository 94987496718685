import type { FunctionComponent } from 'react';
import { Accordion, AccordionItem } from '@carbon/react';
import { advancedOptionsLabels } from '../../constants/metadata';
import { renderAdvOptionsValue } from './utils/reportBuilderUtils';

interface ParameterSummaryProps {
  settings: AnalyticEngineParams;
}

const SettingsSummary: FunctionComponent<ParameterSummaryProps> = ({
  settings,
}) => {
  const advancedOptionsArr = Object.entries(settings).map(([key, value]) => {
    const { label, labelFormat } = advancedOptionsLabels[key] || {
      label: key,
    };
    return { label, value, labelFormat };
  });

  return (
    <Accordion>
      <AccordionItem title="Advanced Settings" open>
        {advancedOptionsArr.map(({ label, value, labelFormat }) => (
          <div
            key={label}
            className="SettingsSummary"
            data-testid="summary-parameter-wrapper"
          >
            {value && (
              <div className="SettingsSummary__type">
                <span className="SettingsSummary__label">{label}: </span>
                <span>
                  {renderAdvOptionsValue(label, value as string, labelFormat)}
                </span>
              </div>
            )}
          </div>
        ))}
      </AccordionItem>
    </Accordion>
  );
};

export default SettingsSummary;
