import type { FunctionComponent } from 'react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Search } from '@carbon/react';
import CategoryHierarchyTree from './CategoryHierarchy/CategoryHierarchyTree';
import { useDebounce, useWindowDimensions } from '../../utils/hooks';
import { homepageSearchClick } from '../../constants/posthog';
import usePosthog from '../../utils/posthog';

interface HomeCategoryHierarchyProps {
  hierarchy: HierNode[];
  selectedItemPath: string[];
  handleItemClick: (items: HierNode[]) => void;
}

const HomeCategoryHierarchy: FunctionComponent<HomeCategoryHierarchyProps> = ({
  hierarchy,
  selectedItemPath,
  handleItemClick,
}) => {
  const posthogEvent = usePosthog();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 500);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { documentHeight } = useWindowDimensions();

  useLayoutEffect(() => {
    if (containerRef.current) {
      const { top } = containerRef.current.getBoundingClientRect();

      containerRef.current.style.height = `${documentHeight - top - 34}px`;
    }
  }, [documentHeight]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm) {
      posthogEvent(homepageSearchClick, {
        searchValue: debouncedSearchTerm,
      });
    }
  }, [debouncedSearchTerm]);

  const handleSearchTermsChange = (value: string) => {
    setSearchTerm(value);
  };

  return (
    <div className="CategoryHierarchy__container" ref={containerRef}>
      <div className="CategoryHierarchy__searchbar">
        <Search
          placeholder="Search product or select from options"
          onChange={({ target }) => handleSearchTermsChange(target.value)}
          ref={inputRef}
          labelText="Search product or select from options"
          size="lg"
          data-testid="category-hierarchy-searchbar"
        />
      </div>

      <div className="CategoryHierarchy__tree">
        <CategoryHierarchyTree
          nodes={hierarchy}
          searchTerm={debouncedSearchTerm}
          handleItemClick={handleItemClick}
          parentNodes={[]}
          selectedItemPath={selectedItemPath}
        />
      </div>
    </div>
  );
};

export default HomeCategoryHierarchy;
