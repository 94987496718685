import type { SharedUser } from '../components/ShareReport/ShareReport';
import { REPORT_STATUS } from '../components/Workspace';
import {
  BANNER_QUERY_PARAM,
  USER_GROUP_QUERY_PARAM,
} from '../constants/values';

export const getUpdatedRows = (
  run_id: string,
  reportData: ReportMetaData[],
  action: 'UPDATE' | 'DELETE',
  type?: string,
  newFields?: ReportMetaData
): ReportMetaData[] => {
  if (action === 'UPDATE') {
    if (type === 'execution_status' || type === 'report_name') {
      return reportData.map((row) =>
        row.run_id === run_id ? { ...row, ...newFields } : row
      );
    } else {
      return reportData;
    }
  } else {
    return reportData.filter((row) => row.run_id !== run_id);
  }
};

export const updateUserArray = (
  user: SharedUser,
  existing: SharedUser[]
): SharedUser[] => {
  return existing.includes(user)
    ? existing.filter((u: SharedUser) => u !== user)
    : [...existing, user];
};

export const getReportLink = (
  run_id: string,
  bannerId: number,
  groupId: number
) => {
  return `/workspace/view-report/${run_id}?${BANNER_QUERY_PARAM}=${bannerId}&${USER_GROUP_QUERY_PARAM}=${groupId}`;
};

export const getStatusBadge = (
  cellValue: REPORT_STATUS
): { value: REPORT_STATUS; tooltipKey: string } => {
  switch (cellValue) {
    case REPORT_STATUS.PROCESSING:
      return {
        value: REPORT_STATUS.PROCESSING,
        tooltipKey: REPORT_STATUS.PROCESSING,
      };
    case REPORT_STATUS.COMPLETED:
      return {
        value: REPORT_STATUS.COMPLETED,
        tooltipKey: cellValue,
      };
    case REPORT_STATUS.FAILED:
    case REPORT_STATUS.CANCELLED:
    case REPORT_STATUS.NO_DATA:
      return {
        value: REPORT_STATUS.FAILED,
        tooltipKey: cellValue,
      };
  }
};
