import type { FunctionComponent } from 'react';
import { useContext, useRef, useState } from 'react';
import {
  Checkmark,
  Close,
  Copy,
  ThumbsUp,
  ThumbsUpFilled,
  ThumbsDown,
  ThumbsDownFilled,
} from '@carbon/icons-react';
import apiRequest from '../../../api';
import { ModalContext } from '../../../providers/ModalProvider';
import { useAuth0 } from '@auth0/auth0-react';
import Fetch from '../../Fetch';
import { CACHE_KEY } from '../../../constants/api';
import type {
  GenAIAnswerActionsProps,
  GenAIFeedbackOption,
} from '../GenAIPanel.types';

const BUTTON_ACTIVE_DURATION = 2000;

const GenAIAnswerActions: FunctionComponent<GenAIAnswerActionsProps> = ({
  requestId,
  message,
  questionId,
}) => {
  const [isLikeActive, setIsLikeActive] = useState(false);
  const [isDislikeActive, setIsDislikeActive] = useState(false);
  const [isDislikeOptionsVisible, setIsDislikeOptionsVisible] = useState(false);
  const [isSavingFeedback, setIsSavingFeedback] = useState(false);
  const [feedbackItems, setFeedbackItems] = useState<string[]>([]);
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const [isCopied, setIsCopied] = useState(false);
  const { updateModal } = useContext(ModalContext);
  const { getAccessTokenSilently } = useAuth0();
  const copyTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const likeTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const dislikeTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const dislikeSubmitTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const requestError = () => {
    updateModal({
      type: 'error',
      title: 'Something went wrong',
      body: 'There was an error. Please try refreshing your browser. If the issue persists, please contact the helpdesk.',
    });
  };

  const handleCopy = () => {
    clearTimeout(copyTimeoutRef.current);
    navigator.clipboard.writeText(message);
    setIsCopied(true);
    copyTimeoutRef.current = setTimeout(
      () => setIsCopied(false),
      BUTTON_ACTIVE_DURATION
    );
  };

  const submitFeedback = async ({
    isLiked,
    comment,
  }: {
    isLiked: boolean;
    comment?: string;
  }): Promise<void> => {
    const token = await getAccessTokenSilently();

    await apiRequest(`/genai/questions/${questionId}/feedback`, 'POST', token, {
      request_id: requestId,
      is_liked: isLiked,
      response: message,
      comment,
    });
  };

  const clearFeedbackButton = (): void => {
    clearTimeout(likeTimeoutRef.current);
    clearTimeout(dislikeTimeoutRef.current);
    clearTimeout(dislikeSubmitTimeoutRef.current);

    setIsLikeActive(false);
    setIsDislikeActive(false);
    setIsDislikeOptionsVisible(false);
    setActiveItem(null);
  };

  const handleLikeClick = async () => {
    clearFeedbackButton();
    setIsLikeActive(true);

    likeTimeoutRef.current = setTimeout(
      () => setIsLikeActive(false),
      BUTTON_ACTIVE_DURATION
    );

    try {
      setIsSavingFeedback(true);
      await submitFeedback({ isLiked: true });
    } catch {
      setIsLikeActive(false);
      requestError();
    } finally {
      setIsSavingFeedback(false);
    }
  };

  const handleDislikeClick = () => {
    clearFeedbackButton();
    setIsDislikeActive(true);
    setIsDislikeOptionsVisible(true);

    dislikeTimeoutRef.current = setTimeout(
      () => setIsDislikeActive(false),
      BUTTON_ACTIVE_DURATION
    );
  };

  const handleDislikeOptionClick = async (option: string | null) => {
    setActiveItem(option);
    try {
      setIsSavingFeedback(true);

      await submitFeedback({ isLiked: false, comment: option });

      if (!option) {
        setIsDislikeOptionsVisible(false);

        return;
      }

      dislikeSubmitTimeoutRef.current = setTimeout(
        () => setIsDislikeOptionsVisible(false),
        BUTTON_ACTIVE_DURATION
      );
    } catch {
      setActiveItem(null);
      requestError();
    } finally {
      setIsSavingFeedback(false);
    }
  };

  return (
    <div className="GenAIPanel__actions">
      <button
        type="button"
        aria-label="Copy"
        title="Click to copy"
        className={`GenAIPanel__actions-button ${isCopied ? 'active' : ''}`}
        onClick={handleCopy}
      >
        Copy {isCopied ? <Checkmark /> : <Copy />}
      </button>
      <div className="GenAIPanel__actions-container">
        <button
          data-testid="genai-like"
          type="button"
          aria-label="Like answer"
          title="Click to like"
          className="GenAIPanel__actions-feedback"
          onClick={handleLikeClick}
          disabled={isSavingFeedback}
        >
          {isLikeActive ? <ThumbsUpFilled /> : <ThumbsUp />}
        </button>
        <button
          data-testid="genai-dislike"
          type="button"
          aria-label="Dislike answer"
          title="Click to dislike"
          className="GenAIPanel__actions-feedback"
          onClick={handleDislikeClick}
          disabled={isSavingFeedback}
        >
          {isDislikeActive ? <ThumbsDownFilled /> : <ThumbsDown />}
        </button>
      </div>
      {isDislikeOptionsVisible && (
        <div className="GenAIPanel__actions-options">
          <Fetch
            initialData={feedbackItems}
            alwaysFetchOnMount={
              !feedbackItems?.length || !CACHE_KEY.GENAI_FEEDBACK_ITEMS
            }
            onReceiveData={(data: GenAIFeedbackOption) => {
              if (data) {
                setFeedbackItems(data.options);
              }
            }}
            apiUrl="/genai/questions/dislike-options"
            cacheKey={CACHE_KEY.GENAI_FEEDBACK_ITEMS}
            loadingMessage="Loading OneViuAI feedback options..."
          >
            <div className="GenAIPanel__actions-options-header">
              <p className="GenAIPanel__actions-options-title">
                <ThumbsDownFilled /> Can you provide some feedback please:
              </p>
              {!activeItem && (
                <button
                  className="GenAIPanel__actions-options-close"
                  onClick={() => handleDislikeOptionClick(null)}
                  disabled={isSavingFeedback}
                >
                  <Close />
                </button>
              )}
            </div>
            {activeItem ? (
              <>
                <div className="GenAIPanel__message">{activeItem}</div>
                <p className="GenAIPanel__actions-options-text">
                  Thank you for the feedback
                </p>
              </>
            ) : (
              feedbackItems?.length > 0 && (
                <div className="flex direction-column align-start gap--medium">
                  {feedbackItems.map((feedbackItem) => (
                    <button
                      key={feedbackItem}
                      className="GenAIPanel__button"
                      onClick={() => handleDislikeOptionClick(feedbackItem)}
                      disabled={isSavingFeedback}
                    >
                      {feedbackItem}
                    </button>
                  ))}
                </div>
              )
            )}
          </Fetch>
        </div>
      )}
    </div>
  );
};

export default GenAIAnswerActions;
