import type { FunctionComponent, ReactNode } from 'react';

interface CardHeaderProps {
  title: string;
  icon?: ReactNode;
}

const CardHeader: FunctionComponent<CardHeaderProps> = ({ icon, title }) => {
  return (
    <div className="CardHeader__container">
      {icon && <div className="CardHeader__icon">{icon}</div>}
      <div className="CardHeader__title">{title}</div>
    </div>
  );
};

export default CardHeader;
