import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AppContext } from './AppProvider';
import { useInterval } from '../hooks/useInterval';

export const WEBSOCKET_BROADCAST_CHANNEL = 'WEBSOCKET_BROADCAST_CHANNEL';
const EXPIRY_CHECK_INTERVAL = 3_000;
const PING_INTERVAL = 60_000;

interface WebsocketProviderProps {
  children?: ReactNode;
}

interface WebsocketContextType {
  websocket: WebSocket;
  broadcastChannel: BroadcastChannel;
}

export const WebsocketContext = createContext<WebsocketContextType | null>(
  null
);

const wsUrl = process.env.REACT_APP_WEB_SOCKET_URL;

const WebsocketProvider: FunctionComponent<WebsocketProviderProps> = ({
  children,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { user } = useContext(AppContext);
  const [websocket, setWebsocket] = useState<WebSocket>(null);
  const broadcastChannel = new BroadcastChannel(WEBSOCKET_BROADCAST_CHANNEL);

  const connect = async (getNewToken: boolean) => {
    const token = await getAccessTokenSilently({ ignoreCache: getNewToken });
    const websocket = new WebSocket(`${wsUrl}?access_token=Bearer ${token}`);
    websocket.onmessage = (event) => {
      broadcastChannel.postMessage(event.data);
    };

    setWebsocket(websocket);
  };

  useEffect(() => {
    if (wsUrl && user) {
      connect(false);
    }
  }, [user]);

  useEffect(() => {
    return () => {
      if (websocket) {
        websocket.close();
      }
    };
  }, [websocket]);

  useInterval(
    () => {
      const secondsToExpire = (user?.tokenExpiry * 1000 - Date.now()) / 1000;

      if (websocket.readyState === websocket.CLOSED) {
        connect(false);
      } else if (secondsToExpire < 60) {
        websocket.close();
        connect(true);
      }
    },
    { delay: websocket ? EXPIRY_CHECK_INTERVAL : null }
  );

  useInterval(() => websocket.send(JSON.stringify({ data: '/ping' })), {
    delay: websocket ? PING_INTERVAL : null,
  });

  const websocketContext: WebsocketContextType = useMemo(() => {
    return {
      websocket,
      broadcastChannel,
    };
  }, [websocket]);

  return (
    <WebsocketContext.Provider value={websocketContext}>
      {children}
    </WebsocketContext.Provider>
  );
};

export default WebsocketProvider;
